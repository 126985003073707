// Routes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Welcome from '../welcome/Welcome';
import App from '../App';
import ForgotPassword from '../forgot-password/ForgotPassword';

const SystemRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<App />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <SystemRoutes />
    </Router>
  );
};

export default AppRoutes;
