import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Table from '../../components/table/Table';
import DatePicker from "react-datepicker";
import Modal from 'react-modal';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ListEmails = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState(null);
    const [emailVisualization, setEmailVisualization] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const tableConfig = [
        { 'property': 'dataEnvio', 'headName': i18n.t('emails.createdAt'), 'sorteable': true, 'type': 'date' },
        { 'property': 'remetente', 'headName': i18n.t('emails.sender'), 'sorteable': true },
        { 'property': 'destinatario', 'headName': i18n.t('emails.receiver'), 'sorteable': true },
        { 'property': 'assunto', 'headName': i18n.t('emails.subject'), 'sorteable': true },
        { 'property': 'tipo', 'headName': i18n.t('emails.type'), 'sorteable': true },
        { 'property': 'foiAberto', 'headName': i18n.t('emails.read'), 'sorteable': true, 'type': 'boolean' },
        { 'property': 'status', 'headName': i18n.t('emails.status'), 'sorteable': true }
    ]
    const actions = [
        { 'icon': 'fas fa-eye', 'function' : 'visualize'},
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'},
        { 'icon': 'fas fa-share', 'function' : 'resend'}
    ]

    useEffect(() => {
        fetchEmails();
    }, []);

    const submitSearchForm = async (event) => {
        event.preventDefault();
        fetchEmails();
    }
    
    async function fetchEmails() {
        setLoading(true);
        try {
            let response = await api.post('/emailsenviado/listar', dadosBusca)
            setEmails(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('emails.failedToLoad') + ": " + error.response.data.message});
        }
        setLoading(false);
    }

    async function fetchEmail(id) {
        try {
            let response = await api.get(`/emailsenviado/${id}`)
            setEmailVisualization(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('emails.failedToLoad') + ": " + error.response.data.message});
        }
    }

    async function deleteEmail(id) {
        try {
            await api.delete(`/emailsenviado/${id}`)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('emails.deletedSuccessfully')});
            fetchEmails();
        } catch(error) {
            setMessageConfig({'message': i18n.t('emails.failedToDelete') + ": " + error.response.data.message});
        }
    }

    async function resendEmail(id) {
        try {
            await api.patch(`/emailsenviado/${id}`)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('emails.resendSuccessfully')});
        } catch(error) {
            setMessageConfig({'message': i18n.t('emails.failedToResend') + ": " + error.response.data.message});
        }
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'visualize') {
            await fetchEmail(object.id);
            setModalOpen(true);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('emails.deleteConfirmation'))) {
                await deleteEmail(object.id);
            }
        } else if(actionName === 'resend') {
            await resendEmail(object.id);
        }
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('emails.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('emails.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <form method="POST" onSubmit={submitSearchForm}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="status">{i18n.t('emails.status')}:</label>
                                            <select id="status" className="form-control" onChange={(e) => setDadosBusca({...dadosBusca, 'status' : e.target.value})}>
                                                <option value="">{i18n.t('selectAnOption')}</option>
                                                <option value="SUCESSO">ENVIADO COM SUCESSO</option>
                                                <option value="ERRO">ERRO - NÃO ENVIADO</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="receiver">{i18n.t('emails.receiver')}:</label>
                                            <input id="receiver" type="text" className="form-control" maxLength="80" onChange={(e) => setDadosBusca({...dadosBusca, 'destinatario' : e.target.value})}></input>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="form-group display-child-in-column">
                                            <label htmlFor="createdAt">{i18n.t('emails.createdAt')}:</label>
                                            <DatePicker id="createdAt" dateFormat="dd/MM/yyyy" selected={dadosBusca.dataEnvio} className="form-control" onChange={(date) => setDadosBusca({ ...dadosBusca, 'dataEnvio' : date.getTime() })} />
                                        </div>
                                    </div>

                                    <div className="pull-right">
                                        <button className="btn btn-primary submit-button" type="submit">{i18n.t('search')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('emails.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchEmails} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={emails}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalOpen} className="Modal" overlayClassName="Overlay" closeTimeoutMS={200}>
                <div>
                    <div className="modal-header">
                        <h1 className="modal-title centralize"><b>{i18n.t('emails.emailContent')}</b></h1>
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: emailVisualization.mensagem }} />
                    </div>
                    <div className="modal-footer">
                        <a className="btn btn-danger" data-dismiss="modal" onClick={() => setModalOpen(false)}>{i18n.t('cancel')}</a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ListEmails