import React, { useEffect, useState } from 'react';
import i18n from '../../../util/translation/i18n';
import api from '../../../services/config/AxiosConfig';
import DatePicker from "react-datepicker";
import Table from '../../../components/table/Table';

const ManagePizzaEvent = ({ setMessageConfig, changeMenu }) => {
    const [dadosBusca, setDadosBusca] = useState({});
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(false);

    const tableConfig = [
        { 'property': 'nome', 'headName': i18n.t('pizza.event.eventName'), 'sorteable': true },
        { 'property': 'dataEvento', 'headName': i18n.t('pizza.event.eventDate'), 'sorteable': true, 'type': 'date' },
        { 'property': 'nomeStatus', 'headName': i18n.t('pizza.event.status'), 'sorteable': true },
        { 'property': 'quantidadePizzasVendidas', 'headName': i18n.t('pizza.event.quantitySoldPizzas'), 'sorteable': true },
        { 'property': 'valorGastoMaterial', 'headName': i18n.t('pizza.event.spendAmount'), 'sorteable': true, 'type': 'money' },
        { 'property': 'valorArrecadado', 'headName': i18n.t('pizza.event.collectedAmount'), 'sorteable': true, 'type': 'money' }
    ]
    const actions = [
        { 'icon': 'fas fa-eye', 'function' : 'visualize' },
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete' }
    ]

    useEffect(() => {
        fetchEvents();
    }, []);

    async function fetchEvents() {
        setLoading(true);
        try {
            let response = await api.post('/admin/pizza/listar', dadosBusca)
            setEvents(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.event.loadFetchFailed')});
        }
        setLoading(false);
    }

    async function createEvent() {
        try {
            await api.post(`/admin/pizza`, event);
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('pizza.event.createdSuccessfully')});
            fetchEvents();
        } catch(error) {
            console.log(error);
            setMessageConfig({'message': i18n.t('pizza.event.failedToCreate') + ": " + error.response.data.message});
        }
    }

    async function deleteEvent(eventId) {
        try {
            await api.delete(`/admin/pizza/${eventId}`)
            fetchEvents();
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.event.failedToDelete') + ": " + error.response.data.message});
        }
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'visualize') {
            changeMenu(object, 'report');
        } else if(actionName === 'delete') {
            if(object.status == 'FINALIZADO') {
                setMessageConfig({'message': i18n.t('pizza.event.cantDeleteFinished')});
            } else {
                if(window.confirm(i18n.t('pizza.event.confirmDeletion'))) {
                    await deleteEvent(object.id);
                }
            }
        }
    }

    return ( 
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('pizza.event.title')}</h1>
                </div>
            </div>

            <div className="container-fluid">
                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">Criar Evento de Pizza</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="name">{i18n.t('pizza.event.name')}:</label>
                                    <input id="name" type="text" className="form-control" value={event.nome} maxLength="70" onChange={(e) => setEvent({...event, nome : e.target.value})}></input>
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="eventDate">{i18n.t('pizza.event.eventDate')}:</label>
                                    <DatePicker id="eventDate" dateFormat="dd/MM/yyyy" selected={event.dataEvento} className="form-control" onChange={(date) => setEvent({...event, 'dataEvento' : date.getTime()})} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="initialOrderDate">{i18n.t('pizza.event.initialOrderDate')}:</label>
                                    <DatePicker id="initialOrderDate" dateFormat="dd/MM/yyyy" selected={event.dataInicioPedidos} className="form-control" onChange={(date) => setEvent({...event, 'dataInicioPedidos' : date.getTime()})} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="finalOrderDate">{i18n.t('pizza.event.finalOrderDate')}:</label>
                                    <DatePicker id="finalOrderDate" dateFormat="dd/MM/yyyy" selected={event.dataFinalPedidos} className="form-control" onChange={(date) => setEvent({...event, 'dataFinalPedidos' : date.getTime()})} />
                                </div>
                            </div>
                        </div>
    
                        <div className="pull-right">
                            <button type="submit" className="btn btn-primary submit-button" onClick={() => createEvent()}>{i18n.t('create')}</button>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">Eventos de Pizza</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchEvents} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={events}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ManagePizzaEvent;