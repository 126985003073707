import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Table from '../../components/table/Table';
import TextArea from '../../components/textarea/TextArea';
import DatePicker from "react-datepicker";

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const VersionControl = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaOrdenacao': 'dataImplantacao', 'colunaDirecao': 2 });
    const [versions, setVersions] = useState(null);
    const [createVersion, setCreateVersion] = useState({});
    const [loading, setLoading] = useState(false);

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('versionControl.id'), 'sorteable': true},
        { 'property': 'versao', 'headName': i18n.t('versionControl.version'), 'sorteable': true },
        { 'property': 'dataImplantacao', 'headName': i18n.t('versionControl.deployDate'), 'sorteable': true, 'type': 'datetime' },
        { 'property': 'responsavel', 'headName': i18n.t('versionControl.responsable'), 'sorteable': true },
        { 'property': 'descricao', 'headName': i18n.t('versionControl.description'), 'sorteable': true }
    ]

    const actions = [
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ];

    useEffect(() => {
        preConfiguration();
        fetchVersions();
    }, []);

    const preConfiguration = () => {
        const systemVersion = localStorage.getItem('versao');
        setCreateVersion({ 'versao' : systemVersion ? systemVersion : '' });
    }
    
    async function fetchVersions() {
        setLoading(true);
        try {
            let response = await api.post('/controleversao/listar', dadosBusca)
            setVersions(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('versionControl.failedToLoad') + ": " + error.response.data.message});
        }
        setLoading(false);
    }

    async function saveVersion() {
        try {
            await api.post('/controleversao', createVersion)
            setCreateVersion({});
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('versionControl.createdSuccessfully')});
            fetchVersions();
        } catch(error) {
            setMessageConfig({'message': i18n.t('versionControl.failedToSave') + ": " + error.response.data.message});
        }
    }

    async function deleteVersion(id) {
        try {
            await api.delete(`/controleversao/${id}`)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('versionControl.deletedSuccessfully')});
            fetchVersions();
        } catch(error) {
            setMessageConfig({'message': i18n.t('versionControl.failedToDelete') + ": " + error.response.data.message});
        }
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'delete') {
            if(window.confirm(i18n.t('versionControl.confirmDeletion'))) {
                await deleteVersion(object.id);
            }
        }
    }

    const setDescription = (text) => {
        setCreateVersion({...createVersion, 'descricao' : text});
    }

    const submitSearchForm = async (event) => {
        event.preventDefault();
        fetchVersions();
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('versionControl.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelCreation" data-toggle="collapse" href="#collapsePainelCreation">{i18n.t('versionControl.create')}</div>
                        <div className="panel-body panel-collapsed collapse in" id="collapsePainelCreation" aria-labelledby="painelCreation">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="createVersion">{i18n.t('versionControl.version')}:</label>
                                        <input id="createVersion" type="text" className="form-control" maxLength="30" value={createVersion.versao} onChange={(e) => setCreateVersion({...createVersion, 'versao' : e.target.value})}></input>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <TextArea setDescription={setDescription} fieldName={i18n.t('versionControl.description')} limit={3000} />
                            </div>
                            
                            <div className="row">
                                <div className="pull-right">
                                    <button className="btn btn-success submit-button" onClick={() => saveVersion()}>{i18n.t('save')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('versionControl.search')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                        <form method="POST" onSubmit={submitSearchForm}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="version">{i18n.t('versionControl.version')}:</label>
                                        <input id="version" type="text" className="form-control text-uppercase" maxLength="30" onChange={(e) => setDadosBusca({...dadosBusca, 'versao' : e.target.value })}></input>
                                    </div>
                                </div>
                                
                                <div className="col-sm-4">
                                    <div className="form-group display-child-in-column">
                                        <label htmlFor="deployDate">{i18n.t('versionControl.deployDate')}:</label>
                                        <DatePicker id="deployDate" selected={dadosBusca.dataImplantacao} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => setDadosBusca({...dadosBusca, 'dataImplantacao' : date ? date.getTime() : delete dadosBusca.dataImplantacao })} />
                                    </div>
                                </div>
                                
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="responsable">{i18n.t('versionControl.responsable')}:</label>
                                        <input id="responsable" type="text" className="form-control text-uppercase" maxLength="200"  onChange={(e) => setDadosBusca({...dadosBusca, 'responsavel' : e.target.value })}></input>
                                    </div>
                                </div>
                            </div> 
                            
                            <div className="pull-right">
                                <button type="submit" className="btn btn-primary" onClick={() => fetchVersions()}>{i18n.t('search')}</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('versionControl.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchVersions} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={versions}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VersionControl;