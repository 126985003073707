import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Table from '../../components/table/Table';
import Modal from 'react-modal';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ManageConfigurations = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [configurations, setConfigurations] = useState(null);
    const [configEdit, setConfigEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const tableConfig = [
        { 'property': 'chave', 'headName': i18n.t('config.name'), 'sorteable' : true },
        { 'property': 'valor', 'headName': i18n.t('config.value'), 'sorteable' : true },
    ]
    const actions = [
        { 'icon': 'fas fa-edit', 'function' : 'edit'},
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ]

    useEffect(() => {
        fetchConfigurations();
    }, []);
    
    async function fetchConfigurations() {
        setLoading(true);
        try {
            let response = await api.post('/configuracoes/listar', dadosBusca)
            setConfigurations(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('config.failedToLoad')});
        }
        setLoading(false);
    }

    async function deleteConfiguration(idConfig) {
        setLoading(true);
        try {
            await api.delete('/configuracoes/' + idConfig)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('config.deleted')});
        } catch(error) {
            setMessageConfig({'message': i18n.t('config.failedToDelete')});
        }
        fetchConfigurations();
    }

    async function saveConfiguration() {
        setOpenEditModal(false);
        setLoading(true);

        try {
            await api.put('/configuracoes/atualizar', configEdit)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('config.updated')});
        } catch(error) {
            setMessageConfig({'message': i18n.t('config.failedToUpdate')});
        }
        
        fetchConfigurations();
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'edit') {
            setConfigEdit(object);
            setOpenEditModal(true);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('config.confirmConfigDeletion'))) {
                await deleteConfiguration(object.chave);
            }
        }
    }

    const setConfigValue = (configValue) => {
        setConfigEdit({ ...configEdit, 'valor' : configValue });
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('config.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('config.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="name">{i18n.t('config.name')}:</label>
                                        <input id="name" type="text" className="form-control" maxLength="200" onChange={(e) => setDadosBusca({...dadosBusca, 'nome' : e.target.value})}></input>
                                    </div>
                                </div>

                                <a onClick={() => fetchConfigurations()}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </a>

                                <div className="pull-right">
                                    <button className="btn btn-primary submit-button" onClick={() => fetchConfigurations()}>{i18n.t('search')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('config.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchConfigurations} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={configurations}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={openEditModal} className="Modal" overlayClassName="Overlay" closeTimeoutMS={200}>
                { configEdit && (
                <div>
                    <div className="modal-header">
                        <h1 className="modal-title centralize"><b>{i18n.t('config.editConfig')}</b></h1>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="form-group">
                                    <label className="break-line" htmlFor="configName">{i18n.t('config.name')}:</label>
                                    <span id="configName" className="text-uppercase">{configEdit.chave}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group">
                                    <label className="break-line" htmlFor="configValue">{i18n.t('config.value')}:</label>
                                    <input id="configValue" type="text" className="form-control" maxLength="200" value={configEdit.valor} onChange={(e) => setConfigValue(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a className="btn btn-success" onClick={() => saveConfiguration()}>Salvar</a>
                        <a className="btn btn-danger" data-dismiss="modal" onClick={() => setOpenEditModal(false)}>{i18n.t('cancel')}</a>
                    </div>
                </div>
                )}
            </Modal>
        </div>
    )
}

export default ManageConfigurations