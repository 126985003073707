import React, { useEffect, useState } from 'react';
import i18n from '../../../util/translation/i18n';
import api from '../../../services/config/AxiosConfig';
import Skeleton from 'react-loading-skeleton'
import TextArea from '../../../components/textarea/TextArea';
import CollectionUtil from '../../../util/collectionUtil';
import MoneyUtil from '../../../util/moneyUtil';
import StaticAutocomplete from '../../../components/autocomplete/StaticAutocomplete';

const SellPizzaOrder = ({ setMessageConfig, changeMenu }) => {
    const [order, setOrder] = useState({});
    const [total, setTotal] = useState(0);
    const [flavours, setFlavours] = useState(null);
    const [nameSuggestions, setNameSuggestions] = useState([]);
    const [phoneSuggestions, setPhoneSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSugestions, setLoadingSugestions] = useState(false);

    useEffect(() => {
        fetchPizzaFavours();
        fetchSuggestions();
    }, []);

    async function fetchPizzaFavours() {
        setLoading(true);
        try {
            var response = await api.get('/pizza/tipos-pizza');
            setFlavours(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.buy.failedToFetchFlavours')});
        }
        setLoading(false);
    }
    
    async function makeOrder() {
        try {
            await api.post('/pizza/registrar-pedido', order);
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('pizza.buy.orderMadeSuccessfully')});
            changeMenu(null, 'sell');
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.buy.failedToOrder')});
        }
    }

    async function fetchSuggestions() {
        setLoadingSugestions(true);
        try {
            var response = await api.get('/pizza/ultimos-clientes');
            setNameSuggestions(response.data);
        } catch(error) {
            console.log(i18n.t('pizza.sell.failedToFetchSuggestions'));
        }
        setLoadingSugestions(false);
    }

    function setKitQuantity(flavour, quantity) {
        var kits = order.kits
        
        if(CollectionUtil.isEmpty(kits)) {
            kits = new Array();
        }

        var item = kits.filter((i) => i.id === flavour.id);
        if(item.length > 0) {
            kits.pop(item);
        }

        kits.push({ 'id': flavour.id , 'quantidade': quantity, 'valor': flavour.valor});
        
        let sum = 0;

        for (var i=0 ; i<kits.length ; i++) {
            sum += kits[i].quantidade * kits[i].valor;
        }

        setTotal(sum);
        setOrder({...order, 'kits': kits});
    }

    const setDescription = (description) => {
        setOrder({...order, 'observacao' : description})
    }

    const setAutocompleteName = (value) => {
        setOrder({...order, 'nome' : value})
        setPhoneSuggestions(nameSuggestions.filter((customer) => customer.nome.toLowerCase() === value.toLowerCase()).map((customer) => customer.telefone));

        if(phoneSuggestions.length == 1) {
            setAutocompletePhone(phoneSuggestions[0]);
        }
    }

    const setAutocompletePhone = (value) => {
        setOrder({...order, 'telefone' : value})
    }

    return ( 
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('pizza.buy.title')}</h1>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('pizza.buy.search')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="nome">{i18n.t('pizza.buy.customerName')}:</label>
                                    <Skeleton style={{ 'height': '35px', display: loadingSugestions ? 'block' : 'none' }} count={1} />
                                    <StaticAutocomplete id="nome"
                                        style={{ display: loadingSugestions ? 'none' : 'block' }}
                                        setValue={setAutocompleteName}
                                        suggestions={nameSuggestions.map((object) => object.nome)}
                                    />
                                </div>
                            </div>
    
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="telefone">{i18n.t('pizza.buy.customerPhone')}:</label>
                                    <StaticAutocomplete id="telefone"
                                        value={order.telefone}
                                        setValue={setAutocompletePhone}
                                        suggestions={phoneSuggestions}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <TextArea setDescription={setDescription} fieldName={i18n.t('pizza.buy.observation')} limit={300} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelMyOrders" data-toggle="collapse" href="#collapsePainelMyOrders">{i18n.t('pizza.buy.chooseKits')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelMyOrders" aria-labelledby="painelMyOrders">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table id="tabela-produtos" className="table table-striped table-bordered table-hover table-condensed js-sticky-table">
                                        <thead className="aw-table-header-solid tableFloatingHeaderOriginal">
                                            <tr>
                                                <th>Sabor</th>
                                                <th>Valor</th>
                                                <th>Quantidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading && (
                                                    <tr>
                                                        <td colSpan="3">
                                                            <Skeleton count={5} style={{ 'height': '35px' }} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            { !loading && CollectionUtil.isEmpty(flavours) && (
                                                <tr>
                                                    <td colSpan="3">{i18n.t('noItemFound')}</td>
                                                </tr>
                                            )}
                                            { !CollectionUtil.isEmpty(flavours) && flavours.map((flavour, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <span>{flavour.nome}</span>
                                                        </td>
                                                        <td>
                                                            <span>{MoneyUtil.format(flavour.valor)}</span>
                                                        </td>
                                                        <td>
                                                            <input className="form-control" type="number" onChange={(e) => setKitQuantity(flavour, e.target.value)}></input>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
                            <div className="pull-right">
                                <span>Total: {MoneyUtil.format(total)}<b></b></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pull-right">
                    <button className="btn btn-primary btn-danger" onClick={() => changeMenu(null, 'sell')}>{i18n.t('cancel')}</button>
                    <button className="btn btn-primary btn-success" onClick={() => makeOrder()}>{i18n.t('save')}</button>
                </div>
            </div>
        </div>
    );
}
 
export default SellPizzaOrder;