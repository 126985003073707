import TableBodyActions from './TableBodyActions';
import CollectionUtil from '../../util/collectionUtil';
import Skeleton from 'react-loading-skeleton'
import i18n from '../../util/translation/i18n';
import DateUtil from '../../util/dateUtil';
import MoneyUtil from '../../util/moneyUtil';

const TableBodyLines = ({ loading, tableConfig, objects, actions, handleTableActions }) => {

    const translatePropertyValue = (object, property, tableConfigs) => {
        const type = tableConfigs.type;
        let value = object[property];

        if(type) {
            if(type === 'boolean') {
                return value ? i18n.t('yes') : i18n.t('no');
            } else if(type === 'date') {
                return DateUtil.formatDate(value);
            } else if(type === 'datetime') {
                return DateUtil.formatDateTime(value);
            } else if(type === 'money') {
                return MoneyUtil.format(value);
            } else if(type == 'object') {
                value = object[property.substr(0, property.indexOf('.'))];
                if(value) {
                    const propertyName = tableConfigs.property;
                    if(propertyName.indexOf('.') >= 0) {
                        const innerProperty = propertyName.substr(propertyName.indexOf('.') + 1);
                        return value[innerProperty];
                    }
                } else {
                    return "";
                }
            }
        }
            
        return value;
    }

    return (
        <>
            {
                loading && (
                    <tr>
                        <td colSpan={tableConfig.length + 1}>
                            <Skeleton count={10} style={{ 'height': '35px' }} />
                        </td>
                    </tr>
                )
            }
            { !loading && CollectionUtil.isEmpty(objects) && (
                <tr>
                    <td colSpan={tableConfig.length + 1}>{i18n.t('noItemFound')}</td>
                </tr>
            )}
            { !loading && !CollectionUtil.isEmpty(objects) && (
                objects.content.map((object, rowIndex) => (
                    <tr key={rowIndex}>
                        {
                            tableConfig.map((column, colIndex) => (
                                <td key={colIndex}>
                                    <span>{translatePropertyValue(object, column.property, tableConfig[colIndex])}</span>
                                </td>
                            ))
                        }
                        <TableBodyActions object={object} actions={actions} handleTableActions={handleTableActions} />
                    </tr>
                ))
            )}
        </>
    )
}

export default TableBodyLines;