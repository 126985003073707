import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import DatePicker from "react-datepicker";
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/table/Table';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ListLogs = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [logs, setLogs] = useState(null);
    const [logTypes, setLogTypes] = useState([]);
    const [logCategories, setLogCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);

    const tableConfig = [
        { 'property': 'evento', 'headName': i18n.t('logs.event'), 'sorteable': true},
        { 'property': 'categoria', 'headName': i18n.t('logs.category'), 'sorteable': true },
        { 'property': 'ip', 'headName': i18n.t('logs.ip'), 'sorteable': true },
        { 'property': 'dataOcorrencia', 'headName': i18n.t('logs.occurenceDate'), 'sorteable': true, 'type': 'date'  },
        { 'property': 'usuario.email', 'headName': i18n.t('logs.user'), 'sorteable': true, 'type': 'object'  },
        { 'property': 'detalhe', 'headName': i18n.t('logs.detail'), 'sorteable': true}
    ]

    const setEvent = (event) => {
        if(event.length === 0) {
            delete dadosBusca.evento;
        } else {
            dadosBusca.evento = event;
            setDadosBusca(dadosBusca);
            fetchLogsCategories(event);
        }
    }

    const setCategory = (event) => {
        if(event.length === 0) {
            delete dadosBusca.categoria;
        } else {
            dadosBusca.categoria = event;
            setDadosBusca(dadosBusca);
        }
    }

    useEffect(() => {
        fetchLogs();
        fetchLogsTypes();
    }, []);
    
    async function fetchLogs() {
        setLoading(true);
        try {
            let response = await api.post('/logs/buscar', dadosBusca)
            setLogs(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('logs.failedToLoad')});
        }
        setLoading(false);
    }

    async function fetchLogsTypes() {
        try {
            let response = await api.get('/logs/eventos')
            setLogTypes(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('logs.failedToLoad')});
        }
    }

    async function fetchLogsCategories(event) {
        try {
            setLoadingCategories(true);
            let response = await api.get(`/logs/categorias/${event}`)
            setLogCategories(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('logs.failedToLoad')});
        }
        setLoadingCategories(false);
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('logs.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('logs.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="events">{i18n.t('logs.event')}:</label>
                                        <select id="events" className="form-control" onChange={(e) => setEvent(e.target.value)}>
                                            <option value="">{i18n.t('logs.eventSelection')}</option>
                                            {
                                                logTypes.length > 0 && (
                                                    logTypes.map((logType, index) => {
                                                        return (
                                                            <option key={index} value={logType}>{logType}</option>
                                                        )
                                                    })
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="categories">{i18n.t('logs.category')}:</label>
                                        <select id="categories" 
                                                className="form-control" 
                                                onChange={(e) => setCategory(e.target.value)}
                                                style={{ display: loadingCategories ? 'none' : 'block' }} >
                                            <option value="">{i18n.t('logs.eventSelection')}</option>
                                            {
                                                logCategories.length > 0 && (
                                                    logCategories.map((logCategory, index) => {
                                                        return (
                                                            <option key={index} value={logCategory}>{logCategory}</option>
                                                        )
                                                    })
                                                )
                                            }
                                        </select>
                                        <Skeleton style={{ 'height': '35px', display: loadingCategories ? 'block' : 'none' }} count={1} />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group display-child-in-column">
                                        <label htmlFor="occurenceDate">{i18n.t('logs.occurenceDate')}:</label> 
                                        <DatePicker id="occurenceDate" selected={dadosBusca.dataOcorrencia} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => setDadosBusca({...dadosBusca, 'dataOcorrencia' : date.getTime() })} />
                                    </div>
                                </div>

                                <div className="pull-right">
                                    <button className="btn btn-primary submit-button" onClick={() => fetchLogs()}>{i18n.t('search')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('logs.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchLogs} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={logs}>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListLogs