class MoneyUtil {
  
    static format = (value) => {
        if(value) {
            return value.toLocaleString("pt-br", {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })
        }
        
        return value;
    };
}
  
export default MoneyUtil;