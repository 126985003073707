const TableBodyActions = ({ object, actions, handleTableActions }) => {

    const handleClick = (object, action) => {
        console.log('action performing: ' + action.loading);
        if(action.loading == true) {
            console.log('action performing: loading true');
            action.icon = 'fa fa-spinner fa-spin';
        }

        handleTableActions(object, action.function);
    }

    return (
        <td className="display-child-in-line">
            {
                actions && actions.length > 0 && (
                    actions.map((action, actionIndex) => (
                        <a className={"action-icon " + (action.class ? action.class : '')} key={actionIndex} onClick={() => handleClick(object, action)}>
                            <i className={action.icon}></i>
                        </a>
                    ))
                )
            }
        </td>
    )
}

export default TableBodyActions;