class DateUtil {
  
    static formatDate = (date) => {
      if(date) {
        let locate = navigator.language || 'pt-BT';
        let options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(date).toLocaleDateString(locate, options);
      } else {
        return '';
      }
    };

    static formatDateTime = (dateTime) => {
        let locate = navigator.language || 'pt-BT';
        let options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false, // Use 24-hour format
        };
        return new Date(dateTime).toLocaleString(locate, options);
      };
  }
  
  export default DateUtil;