// Welcome.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../util/translation/i18n';
import api from '../services/config/AxiosConfig';
import DisplayMessage from '../components/display-message/DisplayMessage';
import ManageUsers from '../features/users/ManageUsers';
import Menu from '../menu/Menu';
import Modal from 'react-modal';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Welcome.css'
import ListLogs from '../features/log/ListLogs';
import ListarPalestras from '../features/palestra/ListarPalestras';
import ManageConfigurations from '../features/configurations/ManageConfigurations';
import ListEmails from '../features/emails/ListEmails';
import ManagePermissions from '../features/permissions/ManagePermissions';
import ManageGroups from '../features/permissions/ManageGroups';
import VersionControl from '../features/versionControl/VersionControl';
import MenuManagement from '../components/MenuManagement';
import ListMessages from '../features/messages/ListMessages';

const Welcome = () => {
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(true);
  const [messageConfig, setMessageConfig] = useState({});
  const [changePasswordDto, setChangePasswordDto] = useState({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isLogoffModalOpen, setIsLogoffModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUserProfile();
  }, []);

  async function getUserProfile() {
    try {
      let response = await api.get('/usuario/me')
      setUser(response.data);
    } catch(error) {
      setMessageConfig({'message': i18n.t('loadUserProfileFailed')});
    }
  }
  
  async function deletarConta() {
    if(window.confirm(i18n.t('deleteAccountSure'))) {
      let response = await api.delete(`/usuario/conta/${user.id}`)
      if(response.status == 200) {
        localStorage.clear();
        navigate("/login");
      }
    }
  }

  async function changePassword() {
    setIsChangePasswordModalOpen(false);

    try {
      await api.put('/usuario/alterar-senha', changePasswordDto);
      setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('changePassword.passwordChangedSuccesfully')});
    } catch(error) {
      setMessageConfig({'message': error.response.data.message});
    }
  }

  const handleCloseMessage = () => {
    setMessageConfig({});
  };

  const logoff = () => {
    localStorage.clear();
    navigate("/")
  }

  function chooseMenu(role) {
    handleCloseMessage();
    setShowMenu(false);
    setMenu(role)
  }

  return (
    <>
      <nav className="welcome-navbar navbar fixed-top flex-md-nowrap p-0">
        <ul className="nav navbar-nav">
          <li style={{ 'margin-left': '20px', 'margin-right': '20px'}}>
            <i className="fas fa-bars" style={{ color: 'white' }} onClick={() => setShowMenu(!showMenu)}></i>
          </li>
        </ul>
        <a className="navbar-brand">{i18n.t('systemShortName')}</a>
        <ul className="navbar-nav px-3 lateral-options">
          <li className="nav-item text-nowrap">
            <a className="nav-link" onClick={() => setIsProfileModalOpen(true)}><i className="fas fa-user"></i></a>
          </li>
          <li className="nav-item text-nowrap">
            <a className="nav-link" onClick={() => setIsChangePasswordModalOpen(true)}><i className="fas fa-key"></i></a>
          </li>
          <li className="nav-item text-nowrap">
            <a className="nav-link" onClick={() => setIsLogoffModalOpen(true)}><i className="fas fa-sign-out-alt"></i></a>
          </li>
        </ul>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <nav className={"col-md-2 d-md-block bg-light sidebar " + (showMenu ? 'd-none' : '')}>
            <div className="sidebar-sticky">
              <Menu user={user} setMessageConfig={setMessageConfig} selectedMenu={menu} chooseMenu={chooseMenu}></Menu>
            </div>
          </nav>

          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
            <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom main-div">
              <DisplayMessage messageConfig={messageConfig} onClose={handleCloseMessage} />

              { menu == 'GERENCIAR_USUARIOS' && (
                <ManageUsers setMessageConfig={setMessageConfig}></ManageUsers>
              )}
              { menu == 'LOG' && (
                <ListLogs setMessageConfig={setMessageConfig}></ListLogs>
              )}
              { menu == 'PALESTRA' && (
                <ListarPalestras setMessageConfig={setMessageConfig}></ListarPalestras>
              )}
              { menu == 'CONFIGURACOES' && (
                <ManageConfigurations setMessageConfig={setMessageConfig}></ManageConfigurations>
              )}
              { menu == 'GERENCIAR_EMAILS' && (
                <ListEmails setMessageConfig={setMessageConfig}></ListEmails>
              )}
              { menu == 'GERENCIAR_PERMISSOES' && (
                <ManagePermissions setMessageConfig={setMessageConfig}></ManagePermissions>
              )}
              { menu == 'CONTROLE_VERSAO' && (
                <VersionControl setMessageConfig={setMessageConfig}></VersionControl>
              )}
              { menu == 'GERENCIAR_GRUPOS' && (
                <ManageGroups setMessageConfig={setMessageConfig}></ManageGroups>
              )}
              { menu == 'PEDIDO_PIZZA' && (
                <MenuManagement setMessageConfig={setMessageConfig} actualMenu="sell"></MenuManagement>
              )}
              { menu == 'GERENCIAR_EVENTOS_PIZZA' && (
                <MenuManagement setMessageConfig={setMessageConfig} actualMenu="event"></MenuManagement>
              )}
              { menu == 'GERENCIAR_MENSAGEM' && (
                <ListMessages setMessageConfig={setMessageConfig}></ListMessages>
              )}
            </div>
          </main>
        </div>
      </div>

      <Modal isOpen={isChangePasswordModalOpen} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
        { user && (
          <div>
            <div className="modal-header">
              <h1 className="modal-title centralize"><b>{i18n.t('changePassword.title')}</b></h1>
            </div>
            <div className="modal-body">
            <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="break-line" htmlFor="username">{i18n.t('changePassword.email')}:</label> 
                    <span id="username">{user.email}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="break-line" htmlFor="nome">{i18n.t('changePassword.username')}:</label>
                    <span id="nome">{user.nome}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="avatar avatar-md avatar-circle dropdown">
                      <a data-toggle="dropdown">
                          <img src={user.imgUrl} className="img-rede-social"></img>
                      </a>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="password">{i18n.t('changePassword.password')}:</label> 
                    <input id="password" type="password" className="form-control" onChange={(e) => setChangePasswordDto({...changePasswordDto, 'senhaAtual' : e.target.value})}></input>
                  </div>
                </div>
              
                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="password">{i18n.t('changePassword.newPassword')}:</label> 
                    <input id="password" type="password" className="form-control" onChange={(e) => setChangePasswordDto({...changePasswordDto, 'novaSenha' : e.target.value})}></input>
                  </div>
                </div>
                
                <div className="col-sm-4">
                  <div className="form-group">
                    <label htmlFor="confirmaPassword">{i18n.t('changePassword.confirmPassword')}:</label> 
                    <input id="confirmaPassword" type="password" className="form-control" onChange={(e) => setChangePasswordDto({...changePasswordDto, 'confirmacaoSenha' : e.target.value})}></input>
                  </div>
                </div>
              </div>
              
              <div className="text-center">
                <span className="negrito">{i18n.t('changePassword.redirectMessage')}</span>
              </div>
            </div>
            <div className="modal-footer">
              <a className="btn btn-success" onClick={() => changePassword()}>{i18n.t('save')}</a>
              <a className="btn btn-danger" data-dismiss="modal" onClick={() => setIsChangePasswordModalOpen(false)}>{i18n.t('cancel')}</a>
            </div>
          </div>
        )}
      </Modal>

      <Modal isOpen={isProfileModalOpen} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
        { user && (
          <div>
            <div className="modal-header">
                <h1 className="modal-title centralize"><b>{i18n.t('userProfile.title')}</b></h1>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                <div className="row">
                  <div className="form-group">
                    <label className="break-line" htmlFor="nome">{i18n.t('userProfile.username')}:</label>
                    <span id="nome" className="text-uppercase">{user.nome}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group">
                    <label className="break-line" htmlFor="email">{i18n.t('userProfile.email')}:</label>
                    <span id="email" className="text-uppercase">{user.email}</span>
                  </div>
                </div>
                
                <div className="row">
                  <div className="form-group">
                    <label className="break-line" htmlFor="telefone">{i18n.t('userProfile.phone')}:</label>
                    <span id="telefone">{user.telefone}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a className="btn btn-warning" onClick={() => deletarConta()}>{i18n.t('userProfile.deleteAccount')}</a>
              <a className="btn btn-primary">{i18n.t('edit')}</a>
              <a className="btn btn-danger" data-dismiss="modal" onClick={() => setIsProfileModalOpen(false)}>{i18n.t('cancel')}</a>
            </div>
        </div>
        )}
      </Modal>

      <Modal isOpen={isLogoffModalOpen} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
        <div>
          <div className="modal-header">
            <h1 className="modal-title centralize"><b>Deseja sair do sistema?</b></h1>
          </div>
          <div className="modal-footer">
            <a className="btn btn-primary" onClick={logoff}>{i18n.t('yes')}</a>
            <a className="btn btn-danger" data-dismiss="modal" onClick={() => setIsLogoffModalOpen(false)}>{i18n.t('cancel')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Welcome;
