import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import TextArea from '../../components/textarea/TextArea';
import Table from '../../components/table/Table';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ManagePermissions = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [permissions, setPermissions] = useState(null);
    const [createPermission, setCreatePermission] = useState({});
    const [loading, setLoading] = useState(false);

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('permissions.id'), 'sorteable': true},
        { 'property': 'nome', 'headName': i18n.t('permissions.name'), 'sorteable': true },
        { 'property': 'descricao', 'headName': i18n.t('permissions.description'), 'sorteable': true },
        { 'property': 'url', 'headName': i18n.t('permissions.url'), 'sorteable': true },
        { 'property': 'icone', 'headName': i18n.t('permissions.icon'), 'sorteable': true },
        { 'property': 'mostrarNoMenu', 'headName': i18n.t('permissions.showOnMenu'), 'sorteable': true, 'type': 'boolean' }
    ]

    const actions = [
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ];

    useEffect(() => {
        fetchPermissions();
    }, []);
    
    async function fetchPermissions() {
        setLoading(true);
        try {
            let response = await api.post('/permissao/listar', dadosBusca)
            setPermissions(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('permissions.failedToLoad')});
        }
        setLoading(false);
    }

    async function savePermission() {
        let response = await api.post('/permissao', createPermission)
        setCreatePermission({});
        setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('permissions.createdSuccessfully')});

        fetchPermissions();
    }

    async function deletePermission(id) {
        let response = await api.delete(`/permissao/${id}`)
        setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('permissions.deletedSuccessfully')});

        fetchPermissions();
    }

    async function setDescription(value) {
        setCreatePermission({ ...createPermission, 'descricao': value });
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'edit') {
            setCreatePermission(object);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('permissions.confirmDeletion'))) {
                await deletePermission(object.id);
            }
        }
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('permissions.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('permissions.create')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="isInternal">{i18n.t('permissions.isInternal')}:</label>
                                        <select id="isInternal" className="form-control" onChange={(e) => setCreatePermission({ ...createPermission, 'mostrarNoMenu': e.target.value })}>
                                            <option value="">{i18n.t('selectAnOption')}</option>
                                            <option value="true">{i18n.t('yes')}</option>
                                            <option value="false">{i18n.t('no')}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="id">{i18n.t('permissions.id')}:</label>
                                        <input id="id" type="text" className="form-control" maxLength="30" onChange={(e) => setCreatePermission({...createPermission, 'id' : e.target.value})}></input>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="name">{i18n.t('permissions.name')}:</label>
                                        <input id="name" type="text" disabled={createPermission.mostrarNoMenu == "true" ? true : false} className="form-control" maxLength="30" onChange={(e) => setCreatePermission({...createPermission, 'nome' : e.target.value})}></input>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="icon">{i18n.t('permissions.icon')}:</label>
                                        <input id="icon" type="text" disabled={createPermission.mostrarNoMenu == "true" ? true : false} className="form-control" maxLength="30" onChange={(e) => setCreatePermission({...createPermission, 'icone' : e.target.value})}></input>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="url">{i18n.t('permissions.url')}:</label>
                                        <input id="url" type="text" disabled={createPermission.mostrarNoMenu == "true" ? true : false} className="form-control" maxLength="200" onChange={(e) => setCreatePermission({...createPermission, 'url' : e.target.value})}></input>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <TextArea setDescription={setDescription} fieldName={i18n.t('permissions.description')} limit={300} />
                            </div>
                            
                            <div className="row">
                                <div className="pull-right">
                                    <button className="btn btn-success submit-button" onClick={() => savePermission()}>{i18n.t('permissions.createNew')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('permissions.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchPermissions} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={permissions}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagePermissions