import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import i18n from '../util/translation/i18n';
import api from '../services/config/AxiosConfig';

import './Menu.css';

const Menu =  ({ user, setMessageConfig, selectedMenu, chooseMenu }) => {
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState({});

    useEffect(() => {
        getUserPermissions();
    }, []);

    async function getUserPermissions() {
        setLoading(true);

        try {
            let response = await api.get('/permissao/me')
            setMenu(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('loadUserPermissionsFailed')});
        }

        setLoading(false);
    }

    return (
        <ul className="nav flex-column">
            { user != null && (
                <li className="aw-menu__item li-avatar">
                    <div className="media">
                        <div className="media-left">
                            <div className="avatar avatar-md avatar-circle dropdown">
                                <a data-toggle="dropdown">
                                    <img src={user.imgUrl} className="img-rede-social"></img>
                                </a>
                            </div>
                        </div>
                        <div className="media-body">
                            <div className="foldable">
                                <h5><a className="username">Olá,</a></h5>
                                <h5 className="text-uppercase avatar">{user.nome}</h5>
                            </div>
                        </div>
                    </div>
                </li>
            )}

            <hr />

            {
                loading && (
                    <li>
                        <Skeleton count={10} style={{ 'height': '35px' }} />
                    </li>
                )
            }
            { 
                !loading && menu.length > 0 && (
                    menu.map((role, index) => {
                        if(role.mostrarNoMenu) {
                            if(role == selectedMenu) {
                                return (
                                    <li className="nav-item" key={index}>
                                        <a className="nav-link active" onClick={() => chooseMenu(role.id)}>
                                            <i className={role.icone}></i><span data-feather="home"></span>{i18n.t(role.id)}  <span className="sr-only">(current)</span>
                                        </a>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="nav-item" key={index}>
                                        <a className="nav-link" onClick={() => chooseMenu(role.id)}>
                                            <i className={role.icone}></i><span data-feather="home"></span>{i18n.t(role.id)}
                                        </a>
                                    </li>
                                );
                            }
                        }
                    })
                )
            }
        </ul>
    )
}

export default Menu