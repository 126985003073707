import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import DatePicker from "react-datepicker";
import Skeleton from 'react-loading-skeleton';
import Modal from 'react-modal';
import Table from '../../components/table/Table';
import TextArea from '../../components/textarea/TextArea';
import StaticAutocomplete from '../../components/autocomplete/StaticAutocomplete';
import DateUtil from '../../util/dateUtil';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ListMessages = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [messages, setMessages] = useState(null);
    const [message, setMessage] = useState({});
    const [lastCreators, setLastCreators] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingLastCreators, setLoadingLastCreators] = useState(false);
    const [openModalCreation, setOpenModalCreation] = useState(false);

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('messages.id'), 'sorteable': true},
        { 'property': 'validoAte', 'headName': i18n.t('messages.validoAte'), 'sorteable': true, 'type': 'date' },
        { 'property': 'dataCriacao', 'headName': i18n.t('messages.dataCriacao'), 'sorteable': true, 'type': 'date' },
        { 'property': 'criadoPor', 'headName': i18n.t('messages.criadoPor'), 'sorteable': true },
        { 'property': 'mensagem', 'headName': i18n.t('messages.mensagem'), 'sorteable': true }
    ]

    const actions = [
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ];

    useEffect(() => {
        fetchMessages();
        fetchLastMessageCreators();
    }, []);
    
    async function fetchMessages() {
        setLoading(true);
        try {
            let response = await api.post('/mensagem/buscar', dadosBusca);
            setMessages(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('messages.failedToLoadMessages')});
        }
        setLoading(false);
    }

    async function fetchLastMessageCreators() {
        setLoadingLastCreators(true);
        try {
            let response = await api.get('/mensagem/ultimos-criadores');
            setLastCreators(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('messages.failedToLoadCreators')});
        }
        setLoadingLastCreators(false);
    }

    async function createMessage() {
        setLoading(true);
        setOpenModalCreation(false);
        try {
            await api.post('/mensagem/', message);
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('messages.createdSuccessfully')});
            setMessage({});
            fetchMessages();
        } catch(error) {
            setMessageConfig({'message': i18n.t('messages.failedToCreate')});
        }
        setLoading(false);
    }

    async function deleteMessage(id) {
        setLoading(true);
        try {
            await api.delete(`/mensagem/${id}`);
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('messages.deletedSuccessfully')});
            fetchMessages();
        } catch(error) {
            setMessageConfig({'message': i18n.t('messages.failedToDelete')});
        }
        setLoading(false);
    }

    const setAutocompleteCreatedBy = (createdBy) => {
        setDadosBusca({...dadosBusca, 'criadoPor': createdBy });
    }

    const setDescription = (value) => {
        setMessage({...message, 'mensagem' : value});
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'delete') {
            if(window.confirm(i18n.t('messages.confirmDeletion'))) {
                await deleteMessage(object.id);
            }
        }
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('messages.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('messages.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="createdBy">{i18n.t('messages.createdBy')}:</label>
                                        <Skeleton style={{ 'height': '35px', display: loadingLastCreators ? 'block' : 'none' }} count={1} />
                                        <StaticAutocomplete id="createdBy"
                                            style={{ display: loadingLastCreators ? 'none' : 'block' }}
                                            setValue={setAutocompleteCreatedBy}
                                            suggestions={lastCreators}
                                    />
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="form-group display-child-in-column">
                                        <label htmlFor="createdAt">{i18n.t('messages.createdAt')}:</label> 
                                        <DatePicker id="createdAt" selected={dadosBusca.dataOcorrencia} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => setDadosBusca({...dadosBusca, 'dataOcorrencia' : date.getTime() })} />
                                    </div>
                                </div>

                                <div className="pull-right">
                                    <button className="btn btn-success" onClick={() => setOpenModalCreation(true)}>{i18n.t('create')}</button>
                                    <button className="btn btn-primary" onClick={() => fetchMessages()}>{i18n.t('search')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('messages.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchMessages} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={messages}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={openModalCreation} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
                <div>
                    <div className="modal-header">
                        <h1 className="modal-title centralize"><b>{i18n.t('messages.create')}</b></h1>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="form-group">
                                    <label className="break-line" htmlFor="validUntil">{i18n.t('messages.validoAte')}:</label>
                                    <DatePicker 
                                        id="validUntil" 
                                        selected={message.validoAte} 
                                        dateFormat="dd/MM/yyyy" 
                                        value={DateUtil.formatDate(message.validoAte)} 
                                        className="form-control" 
                                        onChange={(date) => setMessage({...message, 'validoAte' : date.getTime()})} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group">
                                    <label className="break-line" htmlFor="user">{i18n.t('messages.user')}:</label>
                                    <input id="user" type="text" className="form-control" maxLength="200" value={message.palestrante} onChange={(e) => setMessage({...message, 'idUsuario' : e.target.value})}></input>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group">
                                    <TextArea setDescription={setDescription} fieldName={i18n.t('messages.message')} limit={1000} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a className="btn btn-success" onClick={() => createMessage()}>{i18n.t('save')}</a>
                        <a className="btn btn-danger" data-dismiss="modal" onClick={() => setOpenModalCreation(false)}>{i18n.t('cancel')}</a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ListMessages