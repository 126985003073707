import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Table from '../../components/table/Table';
import Modal from 'react-modal';
import DateUtil from '../../util/dateUtil';
import StringUtil from '../../util/stringUtil';
import CollectionUtil from '../../util/collectionUtil';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ManageGroups = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [groups, setGroups] = useState(null);
    const [createGroup, setCreateGroup] = useState({});
    const [modalPermissionOpen, setModalPermissionOpen] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(false);

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('groups.id'), 'sorteable': true},
        { 'property': 'nome', 'headName': i18n.t('groups.name'), 'sorteable': true },
        { 'property': 'dataCadastro', 'headName': i18n.t('groups.creationDate'), 'sorteable': true, 'type': 'date' }
    ]

    const actions = [
        { 'icon': 'fas fa-list', 'function' : 'managePermissions'},
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'},
    ];

    useEffect(() => {
        fetchGroups();
        fetchPermissions();
    }, []);

    async function fetchGroups() {
        setLoading(true);
        try {
            let response = await api.post('/grupos/listar', dadosBusca)
            setGroups(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('groups.failedToLoad')});
        }
        setLoading(false);
    }

    async function saveGroup() {
        const newGroup = {};
        newGroup.nome = createGroup.nome;

        await api.post('/grupos', newGroup)
        setCreateGroup({});
        setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('groups.createdSuccessfully')});

        fetchGroups();
    }

    async function deleteGroup(id) {
        await api.delete(`/grupos/${id}`)
        setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('groups.deletedSuccessfully')});

        fetchGroups();
    }

    async function fetchPermissions() {
        try {
            let response = await api.post('/permissao/listar', {
                "tamanhoPagina": 100
            })
            setPermissions(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('permissions.failedToLoad')});
        }
    }

    async function choosingPermission(permission) {
        const cloneCreateGroup = { ...createGroup };

        if(CollectionUtil.contains(cloneCreateGroup.permissoes.map(p => p.id), permission.id)) {
            var index = cloneCreateGroup.permissoes.findIndex((r) => r.id === permission.id);
            cloneCreateGroup.permissoes.splice(index, 1);
        } else {
            cloneCreateGroup.permissoes.push(permission);
        }
        setCreateGroup(cloneCreateGroup);
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'managePermissions') {
            setCreateGroup(object);
            setModalPermissionOpen(true);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('groups.confirmDeletion'))) {
                await deleteGroup(object.id);
            }
        }
    }

    async function relatePermissionToGroup() {
        const cloneCreateGroup = { ...createGroup };
        try {
            setModalPermissionOpen(false);
            await api.patch('/grupos/vincularPermissao', {
                "idGrupo": cloneCreateGroup.id,
                "idsPermissoes": cloneCreateGroup.permissoes.map(p => p.id)
            });
            setCreateGroup({});
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('groups.updatedSuccessfully')});
            fetchGroups();
        } catch(error) {
            setMessageConfig({'message': i18n.t('groups.failedToLoad')});
        }
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('groups.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('groups.create')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="name">{i18n.t('groups.name')}:</label>
                                        <input id="name" type="text" className="form-control" maxLength="30" onChange={(e) => setCreateGroup({...createGroup, 'nome' : e.target.value})}></input>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="pull-right">
                                    <button className="btn btn-success submit-button" onClick={() => saveGroup()}>{i18n.t('groups.createNew')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('groups.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchGroups} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={groups}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalPermissionOpen} className="Modal" overlayClassName="Overlay" closeTimeoutMS={200} ariaHideApp={false}>
                <div>
                    <div className="modal-header">
                        <h1 className="modal-title centralize"><b>{i18n.t('groups.relatePermission')}</b></h1>
                    </div>
                    <div className="model-body">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="groupName">{i18n.t('groups.groupName')}:</label>
                                    <span id="groupName" className="text-uppercase">{createGroup.nome}</span>
                                </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="dataCadastro">{i18n.t('groups.creationDate')}:</label>
                                    <span id="dataCadastro">{DateUtil.formatDate(createGroup.dataCadastro)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover table-condensed js-sticky-table">
                                        <thead className="aw-table-header-solid">
                                            <tr>
                                                <th></th>
                                                <th>{i18n.t('groups.permissionName')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !CollectionUtil.isEmpty(permissions) && (
                                                    permissions.content.map((permission, rowIndex) => {
                                                        return (
                                                            <tr key={"tr-permission" + rowIndex}>
                                                                <td>
                                                                    <input type="checkbox" checked={createGroup.permissoes && CollectionUtil.contains(createGroup.permissoes.map((p) => p.id), permission.id)} onChange={() => choosingPermission(permission)}></input>
                                                                </td>
                                                                <td>
                                                                    <span>{StringUtil.isEmpty(permission.nome) ? permission.id : permission.nome}</span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a className="btn btn-success" onClick={() => relatePermissionToGroup()}>{i18n.t('save')}</a>
                        <a className="btn btn-danger" data-dismiss="modal" onClick={() => setModalPermissionOpen(false)}>{i18n.t('cancel')}</a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ManageGroups;