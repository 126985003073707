import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Validations from '../../util/validations';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageUsers = ({ setMessageConfig }) => {
    const [userCreationModalOpen, setUserCreationModalOpen] = useState(false);
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [users, setUsers] = useState(null);
    const [userCreation, setUserCreation] = useState({});
    const [userCreationValidation, setUserCreationValidation] = useState({});
    const [loading, setLoading] = useState(false);

    const handlePageClick = (event) => {
        dadosBusca.numeroPagina = event.selected;
        setDadosBusca(dadosBusca);
        fetchUsers();
    };

    const setStatus = (status) => {
        if(status == "true") {
            setDadosBusca({...dadosBusca, 'status' : true })
        } else if(status == "false") {
            setDadosBusca({...dadosBusca, 'status' : false })
        } else {
            delete dadosBusca.status
            setDadosBusca(dadosBusca)
        }
    }

    const setOrder = (column) => {
        dadosBusca.colunaDirecao = (dadosBusca.colunaDirecao + 1) % 3;

        if(dadosBusca.colunaDirecao == 0) {
            delete dadosBusca.colunaOrdenacao;
        } else {
            dadosBusca.colunaOrdenacao = column;
        }
        
        setDadosBusca(dadosBusca);
        fetchUsers();
    }

    const cancelUserCreation = () => {
        setUserCreationModalOpen(false);
        setUserCreationValidation({});
        setUserCreation({});
    }

    async function enableUser(userId) {
        if(window.confirm(i18n.t('users.enableSure'))) {
            await api.put(`/usuario/ativar/${userId}`)
            fetchUsers();
            setMessageConfig({'message': i18n.t('users.enabledSuccessfully'), 'level': 'SUCCESS'});
        }
    }

    async function disableUser(userId) {
        if(window.confirm(i18n.t('users.disableSure'))) {
            await api.put(`/usuario/inativar/${userId}`)
            fetchUsers();
            setMessageConfig({'message': i18n.t('users.disabledSuccessfully'), 'level': 'SUCCESS'});
        }
    }

    async function createUser() {
        if(validateUser()) {
            try {
                let response = await api.post(`/usuario/cadastrar`, userCreation);
                
                if(response.status != 200) {
                    alert(`Erro ao salvar usuario`);
                } else {
                    cancelUserCreation();
                    setMessageConfig({'message': i18n.t('users.creation.success'), 'level': 'SUCCESS'});
                    fetchUsers();
                }
            } catch(error) {
                alert(`Erro: ` + error.message);
            }
        }
    }

    const validateUser = () => {
        if(!userCreation.email) {
            userCreationValidation.email = i18n.t('users.creation.emailMandatory')
        } else {
            if(!Validations.validateEmail(userCreation.email)) {
                userCreationValidation.email = i18n.t('users.creation.emailInvalid')
            } else {
                delete userCreationValidation.email
            }
        }

        if(!userCreation.nome) {
            userCreationValidation.nome = i18n.t('users.creation.nameMandatory')
        } else {
            delete userCreationValidation.nome
        }

        if(!userCreation.telefone) {
            userCreationValidation.telefone = i18n.t('users.creation.phoneMandatory')
        } else {
            delete userCreationValidation.telefone
        }

        if(!userCreation.senha) {
            userCreationValidation.senha = i18n.t('users.creation.passwordMandatory')
        } else {
            delete userCreationValidation.senha
        }

        if(!userCreation.confirmacaoSenha) {
            userCreationValidation.confirmacaoSenha = i18n.t('users.creation.passwordConfirmationMandatory')
        } else {
            delete userCreationValidation.confirmacaoSenha
        }

        if(userCreation.senha && userCreation.confirmacaoSenha) {
            if(userCreation.senha != userCreation.confirmacaoSenha) {
                userCreationValidation.confirmacaoSenha = i18n.t('users.creation.passwordConfirmationNotEquals')
            } else {
                delete userCreationValidation.confirmacaoSenha;
            }
        }

        setUserCreationValidation(userCreationValidation);
        return Object.keys(userCreationValidation).length == 0;
    }

    const setUserCreationDataAndValidate = (data)  => {
        setUserCreation(data);
        validateUser();
    }

    useEffect(() => {
        fetchUsers();
    }, []);
    
    async function fetchUsers() {
        setLoading(true);
        try {
            let response = await api.post('/usuario/listar', dadosBusca)
            setUsers(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('users.loadFetchFailed')});
        }
        setLoading(false);
    }

    return (
        <>
            <div className="aw-layout-page">
                <div className="page-header">
                    <div className="container-fluid">
                        <h1>{i18n.t('users.title')}</h1>
                    </div>
                </div>
                
                <div className="internal-section">
                    <div className="form-paginado-ordenado js-form-loading">
                        <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('users.search')}</div>
                            <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="nameSearch">{i18n.t('users.name')}:</label> 
                                            <input id="nameSearch" type="text" className="form-control text-uppercase" maxLength="200" onChange={(e) => setDadosBusca({...dadosBusca, 'nome' : e.target.value})}></input>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="emailSearch">{i18n.t('users.email')}:</label>
                                            <input id="emailSearch" type="text" className="form-control" maxLength="80" onChange={(e) => setDadosBusca({...dadosBusca, 'email' : e.target.value})}></input>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="status">{i18n.t('users.status')}:</label>
                                            <select id="status" className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                                <option value="">{i18n.t('users.statusSelection')}</option>
                                                <option value="true">{i18n.t('users.statusEnable')}</option>
                                                <option value="false">{i18n.t('users.statusDisable')}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="pull-right">
                                        <button className="btn btn-success" onClick={() => setUserCreationModalOpen(true)}>Cadastrar Novo</button>
                                        <button className="btn btn-primary submit-button" onClick={() => fetchUsers()}>{i18n.t('search')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('users.result')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-condensed js-sticky-table table-ordered">
                                    <thead className="aw-table-header-solid">
                                        <tr>
                                            <th onClick={() => setOrder('nome')}>{i18n.t('users.name')}</th>
                                            <th onClick={() => setOrder('email')}>{i18n.t('users.email')}</th>
                                            <th onClick={() => setOrder('telefone')}>{i18n.t('users.phone')}</th>
                                            <th onClick={() => setOrder('ativo')}>{i18n.t('users.situation')}</th>
                                            <th>{i18n.t('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading && (
                                                <tr>
                                                    <td colSpan="5">
                                                        <Skeleton count={10} style={{ 'height': '35px' }} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        { users == null || users.content == null || users.content.length == 0 && (
                                            <tr>
                                                <td colspan="5">{i18n.t('noItemFound')}</td>
                                            </tr>
                                        )}
                                        { !loading && users != null && users.content != null && users.content.length > 0 && (
                                            users.content.map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-uppercase">
                                                            <span>{user.nome}</span>
                                                        </td>
                                                        <td className="text-lowercase">
                                                            <span>{user.email}</span>
                                                        </td>
                                                        <td>
                                                            <span>{user.telefone}</span>
                                                        </td>
                                                        <td>
                                                            <span>{user.ativo ? i18n.t('users.statusEnable') : i18n.t('users.statusDisable')}</span>
                                                        </td>
                                                        <td>
                                                            {user.ativo && (
                                                                <a onClick={() => disableUser(user.id)} title={i18n.t('users.disable')}> 
                                                                    <i className="fas fa-trash"></i>
                                                                </a>
                                                            )}
                                                            {!user.ativo && !user.deletado && (
                                                                <a onClick={() => enableUser(user.id)} title={i18n.t('users.enable')}> 
                                                                    <i className="fas fa-power-off"></i>
                                                                </a>
                                                            )}
                                                        </td>
                                                    </tr>       
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>

                                { users != null && users.content != null && users.content.length > 0 && (
                                    <div className="pagination-container">
                                        <ReactPaginate
                                            activeClassName="selected"
                                            previousClassName="previous"
                                            nextClassName="next"
                                            pageClassName=""
                                            breakLabel="..."
                                            nextLabel={i18n.t('next')}
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={users.totalPages}
                                            previousLabel={i18n.t('previous')}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Modal isOpen={userCreationModalOpen} className="Modal" overlayClassName="Overlay" closeTimeoutMS={200}>
            <div>
                <div className="modal-header">
                    <h1 className="modal-title centralize"><b>{i18n.t('users.creation.userProfile')}</b></h1>
                </div>
                <div className="modal-body">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="email">{i18n.t('users.email')}:</label> 
                                    <input
                                        id="email" type="text" maxLength="80"
                                        value={userCreation.email}
                                        onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'email' : e.target.value})}
                                        className={`text-lowercase form-control ${userCreationValidation.email ? 'is-invalid' : ''}`}
                                    />
                                    {userCreationValidation.email && <div className="invalid-feedback">{userCreationValidation.email}</div>}
                                </div>
                            </div>

                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label htmlFor="nome">{i18n.t('users.name')}:</label>
                                    <input
                                        id="nome" type="text" maxLength="200"
                                        value={userCreation.nome}
                                        onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'nome' : e.target.value})}
                                        className={`text-uppercase form-control ${userCreationValidation.nome ? 'is-invalid' : ''}`}
                                    />
                                    {userCreationValidation.nome && <div className="invalid-feedback">{userCreationValidation.nome}</div>}
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm-4">
                                <label htmlFor="telefone">{i18n.t('users.phone')}:</label>
                                <input
                                    id="telefone" type="text" maxLength="15"
                                    value={userCreation.telefone}
                                    onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'telefone' : e.target.value})}
                                    className={`form-control ${userCreationValidation.telefone ? 'is-invalid' : ''}`}
                                />
                                {userCreationValidation.telefone && <div className="invalid-feedback">{userCreationValidation.telefone}</div>}
                            </div>
                            
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="senha">{i18n.t('users.password')}:</label>
                                    <input
                                        id="senha" type="password" maxLength="20"
                                        value={userCreation.senha}
                                        onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'senha' : e.target.value})}
                                        className={`form-control ${userCreationValidation.senha ? 'is-invalid' : ''}`}
                                    />
                                    {userCreationValidation.senha && <div className="invalid-feedback">{userCreationValidation.senha}</div>}
                                </div>
                            </div>
    
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="confirmacaoSenha">{i18n.t('users.passwordConfirmation')}:</label> 
                                    <input
                                        id="confirmacaoSenha" type="password" maxLength="20"
                                        value={userCreation.confirmacaoSenha}
                                        onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'confirmacaoSenha' : e.target.value})}
                                        className={`form-control ${userCreationValidation.confirmacaoSenha ? 'is-invalid' : ''}`}
                                    />
                                    {userCreationValidation.confirmacaoSenha && <div className="invalid-feedback">{userCreationValidation.confirmacaoSenha}</div>}
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="checkbox" style={{"margin-right": "10px"}} /><label htmlFor="emailContent">{i18n.t('users.creation.invitationEmailMessage')}:</label> 
                                    <textarea 
                                        rows="5" cols="200" maxLength="10000"
                                        className="form-control text-uppercase comentario-area"
                                        onChange={(e) => setUserCreationDataAndValidate({...userCreation, 'emailContent' : e.target.value})}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a className="btn btn-success" onClick={() => { createUser() }}>{i18n.t('save')}</a>
                    <a className="btn btn-danger" onClick={() => cancelUserCreation()}>{i18n.t('cancel')}</a>
                </div>
            </div>
        </Modal>
    </>
    )
}

export default ManageUsers