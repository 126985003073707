import React, { useEffect, useState } from 'react';
import i18n from '../../util/translation/i18n';
import api from '../../services/config/AxiosConfig';
import Table from '../../components/table/Table';
import DatePicker from "react-datepicker";
import Modal from 'react-modal';
import StringUtil from '../../util/stringUtil';
import DateUtil from '../../util/dateUtil';

import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

const ListarPalestras = ({ setMessageConfig }) => {
    const [dadosBusca, setDadosBusca] = useState({ 'colunaDirecao': 0 });
    const [palestras, setPalestras] = useState(null);
    const [cadastrarPalestra, setCadastrarPalestra] = useState(false);
    const [lecture, setLecture] = useState({});
    const [loading, setLoading] = useState(false);

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('palestras.id'), 'sorteable': true },
        { 'property': 'tema', 'headName': i18n.t('palestras.theme'), 'sorteable': true },
        { 'property': 'palestrante', 'headName': i18n.t('palestras.speaker'), 'sorteable': true },
        { 'property': 'data', 'headName': i18n.t('palestras.date'), 'sorteable': true, 'type': 'date' },
        { 'property': 'url', 'headName': i18n.t('palestras.url'), 'sorteable': false }
    ]
    const actions = [
        { 'icon': 'fab fa-whatsapp', 'class': 'green', 'function' : 'whatsapp'},
        { 'icon': 'fas fa-edit', 'function' : 'edit'},
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ]

    useEffect(() => {
        fetchPalestras();
    }, []);

    const prepareLectureCreation = () => {
        setCadastrarPalestra(true);
        setLecture(dadosBusca);
    }

    const defineEmbbededUrl = () => {
        var placeholder = "https://www.youtube.com/embed/";
        var videoCode = lecture.url.substr(lecture.url.lastIndexOf("/") + 1);
		
		if(videoCode.indexOf('?') !== -1) {
			videoCode = videoCode.substring(0, videoCode.indexOf('?'));
		}
		
		setLecture({...lecture, 'embedUrl' : placeholder + videoCode});
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'whatsapp') {
            if(!StringUtil.isEmpty(object.whatsappLink)) {
                window.open(object.whatsappLink, "_blank", "noreferrer")
            }
        } else if(actionName === 'edit') {
            setLecture(object);
            setCadastrarPalestra(true);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('palestras.confirmDeletion'))) {
                await deleteLecture(object.id);
            }
        }
    }
    
    async function submitForm(event) {
        event.preventDefault();
        fetchPalestras();
    }

    async function fetchPalestras() {
        setLoading(true);
        try {
            let response = await api.post('/palestra/listar', dadosBusca)
            setPalestras(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('palestras.loadFetchFailed')});
        }
        setLoading(false);
    }

    async function createLecture() {
        setLoading(true);
        try {
            await api.post('/palestra', lecture)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('palestras.createdSuccessfully')});
            fetchPalestras();
        } catch(error) {
            setMessageConfig({'message': i18n.t('palestras.failedToCreate')});
            setLoading(false);
        }
    }

    async function deleteLecture(id) {
        setLoading(true);
        try {
            await api.delete(`/palestra/${id}`)
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('palestras.deletedSuccessfully')});
            fetchPalestras();
        } catch(error) {
            setMessageConfig({'message': i18n.t('palestras.failedToDelete')});
            setLoading(false);
        }
    }

    return (
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('palestras.title')}</h1>
                </div>
            </div>
            
            <div className="internal-section">
                <div className="form-paginado-ordenado js-form-loading">
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('palestras.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <form onSubmit={submitForm}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="theme">{i18n.t('palestras.theme')}:</label> 
                                            <input id="theme" type="text" className="form-control" maxLength="200" onChange={(e) => setDadosBusca({...dadosBusca, 'tema' : e.target.value})}></input>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="speaker">{i18n.t('palestras.speaker')}:</label> 
                                            <input id="speaker" type="text" className="form-control" maxLength="200" onChange={(e) => setDadosBusca({...dadosBusca, 'palestrante' : e.target.value})}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group display-child-in-column">
                                            <label htmlFor="date">{i18n.t('palestras.date')}:</label> 
                                            <DatePicker selected={dadosBusca.data} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => setDadosBusca({...dadosBusca, 'dataOcorrencia' : date.getTime()})} />
                                        </div>
                                    </div>

                                    <div className="pull-right">
                                        <button className="btn btn-success submit-button" onClick={() => prepareLectureCreation()}>{i18n.t('create')}</button>
                                        <button className="btn btn-primary submit-button" onClick={() => fetchPalestras()}>{i18n.t('search')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelResultados" data-toggle="collapse" href="#collapsePainelResultados">{i18n.t('palestras.result')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelResultados" aria-labelledby="painelResultados">
                        <Table 
                            loading={loading} 
                            search={fetchPalestras} 
                            dadosBusca={dadosBusca} 
                            tableConfig={tableConfig} 
                            objects={palestras}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>

                <Modal isOpen={cadastrarPalestra} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
                    <div>
                        <div className="modal-header">
                            <h1 className="modal-title centralize"><b>{i18n.t('palestras.create')}</b></h1>
                        </div>
                        <div className="modal-body">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="form-group">
                                        <label className="break-line" htmlFor="creation-theme">{i18n.t('palestras.theme')}:</label>
                                        <input id="creation-theme" type="text" className="form-control" maxLength="200" value={lecture.tema} onChange={(e) => setLecture({...lecture, 'tema' : e.target.value})}></input>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group">
                                        <label className="break-line" htmlFor="creation-speaker">{i18n.t('palestras.speaker')}:</label>
                                        <input id="creation-speaker" type="text" className="form-control" maxLength="200" value={lecture.palestrante} onChange={(e) => setLecture({...lecture, 'palestrante' : e.target.value})}></input>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="form-group">
                                        <label className="break-line" htmlFor="creation-date">{i18n.t('palestras.date')}:</label>
                                        <DatePicker id="creation-date" selected={dadosBusca.data} dateFormat="dd/MM/yyyy" value={DateUtil.formatDate(lecture.data)} className="form-control" onChange={(date) => setDadosBusca({...dadosBusca, 'dataOcorrencia' : date.getTime()})} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group">
                                        <label className="break-line" htmlFor="url">{i18n.t('palestras.url')}:</label>
                                        <input 
                                            id="url" 
                                            type="text" 
                                            className="form-control" 
                                            maxLength="200" 
                                            value={lecture.url} 
                                            onBlur={() => defineEmbbededUrl()} 
                                            onChange={(e) => setLecture({...lecture, 'url' : e.target.value})}>
                                        </input>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group">
                                        <label className="break-line" htmlFor="url">{i18n.t('palestras.embedUrl')}:</label>
                                        <input id="url" type="text" className="form-control" maxLength="200" value={lecture.embedUrl} placeholder="https://www.youtube.com/embed/"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-success" onClick={() => createLecture()}>{i18n.t('save')}</a>
                            <a className="btn btn-danger" data-dismiss="modal" onClick={() => setCadastrarPalestra(false)}>{i18n.t('cancel')}</a>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ListarPalestras