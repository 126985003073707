import React, { useState, useEffect } from 'react';

const DisplayMessage = ({ messageConfig, onClose }) => {
  const [display, setDisplay] = useState(true);
  const [key, setKey] = useState(0); // Introduce a key for re-rendering

  useEffect(() => {
    if (messageConfig && messageConfig.timeout && messageConfig.timeout > 0) {
      const timeoutId = setTimeout(() => {
        setDisplay(false);
      }, messageConfig.timeout);

      return () => {
        clearTimeout(timeoutId);
        setDisplay(true); // Reset display state when unmounting or changing the key
      };
    }
  }, [messageConfig, key]);

  useEffect(() => {
    setKey(prevKey => prevKey + 1); // Update the key to force re-render
  }, [messageConfig]);

  if (messageConfig && messageConfig.message && messageConfig.message.length > 0) {
    let messageLevel = 'danger';
    if (messageConfig.level === 'ERROR') {
      messageLevel = 'danger';
    } else if (messageConfig.level === 'WARNING') {
      messageLevel = 'warning';
    } else if (messageConfig.level === 'SUCCESS') {
      messageLevel = 'success';
    }

    return (
      <div key={key} style={{ display: display ? 'block' : 'none' }}>
        <div className={`alert alert-dismissible alert-${messageLevel}`} role="alert">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <span>{messageConfig.message}</span>
        </div>
      </div>
    );
  } else {
    return <div id="message"></div>;
  }
};

export default DisplayMessage;
