import React, { useState } from 'react';
import i18n from '../util/translation/i18n';
import { useNavigate } from 'react-router-dom';
import LoginService from '../services/login/LoginService';
import TokenUtil from '../util/tokenUtil'
import './Login.css'

const Login = ({ render, goBack, setMessageConfig }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email) {
      setEmailError(i18n.t('emailRequired'));
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError(i18n.t('passwordRequired'));
    } else {
      setPasswordError('');
    }

    if (email && password) {
      try {
        const data = await LoginService.login(email, password);
  
        if (data.token) {
          const tokenPayload = TokenUtil.parseJwt(data.token);

          if(data.email) {
            const roles = tokenPayload.roles;
      
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenPayload', JSON.stringify(tokenPayload));
            localStorage.setItem('email', tokenPayload.sub);
            localStorage.setItem('versao', data.version);
            localStorage.setItem('roles', roles.map(role => role.authority));
      
            navigate('/welcome');
          } else {
            setMessageConfig({ 'message' : tokenPayload.message });
          }
        } else {
          setMessageConfig({ 'message' : data.message, 'timeout': 4000 });
        }
      } catch (error) {
        setMessageConfig({ 'message' : i18n.t('loginFailure') });
      }
    }
  };

  if(render) {
    return (
      <div>
        <form className="form-signin" method="POST" onSubmit={handleLogin}>
          <div className="row">
            <div className="form-group">
              <label htmlFor="usuarioLogin">{i18n.t('emailLabel')}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`text-lowercase form-control ${emailError ? 'is-invalid' : ''}`}
              />
              {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>
          </div>
          <div className="row">
            <div className="form-group">
            <label className="form-label">{i18n.t('passwordLabel')}</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
              />
              {passwordError && <div className="invalid-feedback">{passwordError}</div>}
            </div>
          </div>
          <div className="row login-buttons">
            <button className="btn btn-lg btn-primary" type="submit">{i18n.t('loginTitle')}</button>
            <button className="btn btn-lg btn-primary" onClick={goBack}>{i18n.t('forgotPasswordButton')}</button>
          </div>
          
          <div className="other-links">
            <div className="text">Ou acesse com:</div>
            <a><i className="fab fa-facebook-f"></i>Facebook</a><a><i className="fab fa-google"></i>Google</a><a><i className="fab fa-github"></i>Github</a>
          </div>
        </form>
      </div>
    )
  } else {
    return null
  }
};

export default Login;
