import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL, timeout: process.env.REACT_APP_API_TIMEOUT });

// Adicione um interceptor para todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Adicione um interceptor para todas as respostas
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Se a resposta indicar um token inválido ou expirado, redirecione para a tela de login
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
       console.log('sessao expirada. efetue login novamente');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenPayload');
        window.location.href = "/"
        useNavigate().navigate('/login');
    }
    return Promise.reject(error);
  }
);

export default api;
