import { useState } from 'react';
import i18n from '../../util/translation/i18n';

import './TextArea.css'

const TextArea = ({ setDescription, fieldName, limit }) => {

    const [field, setField] = useState('');

    const calculateMissing = () => {
        return limit - field.length
    }

    const setFieldDescription = (text) => {
        setField(text);
        setDescription(text);
    }

    return (
        <div className="form-group">
            <label htmlFor="textareafield" className="label-comentario">{fieldName}:</label>
            <span className={"characters-remaining" + (calculateMissing() < 10 ? ' red' : '')}><b>{calculateMissing()}</b> {i18n.t('textarea.missingCharacters')}</span>
            <textarea rows="10" className="form-control" cols="200" maxLength={limit} id="textareafield" onChange={(e) => setFieldDescription(e.target.value)}></textarea>
        </div>
    )
}

export default TextArea;