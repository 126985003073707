import React, { useState } from 'react';
import i18n from '../util/translation/i18n';
import LoginService from '../services/login/LoginService';
import './../login/Login';

const ForgotPassword = ({ render, goBack, setMessageConfig }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleForgotPassword = async() => {
    // Lógica para lidar com a recuperação de senha
    if (!email) {
      setEmailError('Por favor, informe seu email');
    } else {
      setEmailError('');

      try {
        const data = await LoginService.forgotPassword(email.trim());
  
        if (data.error) {
          setMessageConfig({ 'message': data.error });
        } else {
          setMessageConfig({ 'message': data.message, 'level' : 'SUCCESS' });
        }
      } catch (error) {
        setMessageConfig({ 'message': i18n.t('forgotPasswordError')});
      }
    }
  };

  return (
    <>
      {render && (
        <form className="form-signin" method="POST" onSubmit={handleForgotPassword}>
          <div className="row">
            <div className="form-group">
            <label className="form-label">{i18n.t('emailLabel')}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`form-control ${emailError ? 'is-invalid' : ''}`}
              />
              {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>
          </div>
          <div className="row login-buttons">
            <button className="btn btn-lg btn-primary" onClick={handleForgotPassword}>{i18n.t('recoverPassword')}</button>
            <button className="btn btn-lg btn-primary" onClick={goBack}>{i18n.t('goBack')}</button>
          </div>
        </form>
      )}
    </>
  );
};

export default ForgotPassword;
