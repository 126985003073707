import React, { useEffect, useState } from 'react';
import i18n from '../../../util/translation/i18n';
import api from '../../../services/config/AxiosConfig';
import DatePicker from "react-datepicker";
import Table from '../../../components/table/Table';
import Modal from 'react-modal';
import DateUtil from '../../../util/dateUtil';
import MoneyUtil from '../../../util/moneyUtil';
import StringUtil from '../../../util/stringUtil';

const SellPizza = ({ setMessageConfig, changeMenu }) => {
    const [myOrdersSearchData, setMyOrdersSearchData] = useState({ 'colunaDirecao': 0 });
    const [mySalesSearchData, setMySalesSearchData] = useState({ 'colunaDirecao': 0 });
    const [myOrders, setMyOrders] = useState(null);
    const [mySales, setMySales] = useState(null);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModalVisualize, setShowModalVisualize] = useState(false);
    const [showPaymentOrder, setShowPaymentOrder] = useState(false);
    const [showPaymentKit, setShowPaymentKit] = useState(false);
    const [paymentType, setPaymentType] = useState('PIX');

    const tableConfig = [
        { 'property': 'id', 'headName': i18n.t('pizza.sell.orderNumber'), 'sorteable': true },
        { 'property': 'nome', 'headName': i18n.t('pizza.sell.customer'), 'sorteable': true },
        { 'property': 'telefone', 'headName': i18n.t('pizza.sell.phone'), 'sorteable': true },
        { 'property': 'status', 'headName': i18n.t('pizza.sell.status'), 'sorteable': true },
        { 'property': 'dataPedido', 'headName': i18n.t('pizza.sell.orderDate'), 'sorteable': true, 'type': 'date' },
        { 'property': 'valorTotal', 'headName': i18n.t('pizza.sell.amount'), 'sorteable': true, 'type': 'money' }
    ]
    const actions = [
        { 'icon': 'fas fa-eye', 'function' : 'visualize'},
        { 'icon': 'fas fa-trash-alt', 'function' : 'delete'}
    ]

    useEffect(() => {
        reloadPage();
    }, []);

    async function reloadPage() {
        fetchMyOrders();
        fetchMySales();
    }
    
    async function fetchMyOrders() {
        setLoading(true);
        try {
            let response = await api.post('/pizza/meuspedidos', myOrdersSearchData)
            setMyOrders(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.sell.loadFetchMyOrdersFailed')});
        }
        setLoading(false);
    }

    async function fetchMySales() {
        setLoading(true);
        try {
            let response = await api.post('/pizza/minhasvendas', mySalesSearchData)
            setMySales(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.sell.loadFetchMySalesFailed')});
        }
        setLoading(false);
    }

    async function visualizeOrder(id) {
        let response = await api.get(`/pizza/${id}`)
        setOrder(response.data);
        setShowModalVisualize(true);
    }

    const handleSearch = async (event) => {
        event.preventDefault();

        myOrdersSearchData.numeroPagina = 0;
        mySalesSearchData.numeroPagina = 0;

        reloadPage();
    }

    async function deleteOrder(id) {
        try {
            await api.delete(`/pizza/${id}`);
            reloadPage();
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.sell.failedToDelete')});
        }
    }

    async function realizarBaixaPedido(orderId) {
        try {
            if(window.confirm(i18n.t('pizza.sell.confirmOrderPayment'))) {
                setShowModalVisualize(false);
                await api.put(`/pizza/baixa/${orderId}/${paymentType}`);
                reloadPage();
            }
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.sell.failedToPay')});
        }
    }

    async function realizarBaixaKitDoPedido(orderId, kitId) {
        try {
            if(window.confirm(i18n.t('pizza.sell.confirmKitPayment'))) {
                setShowModalVisualize(false);
                await api.put(`/pizza/baixa/${orderId}/kit/${kitId}/${paymentType}`);
                reloadPage();
            }
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.sell.failedToPay')});
        }
    }

    async function handleTableActions(object, actionName) {
        if(actionName === 'visualize') {
            visualizeOrder(object.id);
        } else if(actionName === 'delete') {
            if(window.confirm(i18n.t('pizza.sell.confirmDeletion'))) {
                await deleteOrder(object.id);
            }
        }
    }

    return ( 
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('pizza.sell.title')}</h1>
                </div>
            </div>
            
            <div className="container-fluid">
                <form onSubmit={handleSearch}>
                    <div className="panel panel-warning">
                        <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('pizza.sell.search')}</div>
                        <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="nome">{i18n.t('pizza.sell.name')}:</label>
                                        <input id="nome" type="text" className="form-control" maxLength="70" onChange={(e) => setMySalesSearchData({...mySalesSearchData, 'nome' : e.target.value})}></input>
                                    </div>
                                </div>
        
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="telefone">{i18n.t('pizza.sell.phone')}:</label>
                                        <input id="telefone" type="text" className="form-control" onChange={(e) => setMySalesSearchData({...mySalesSearchData, 'telefone' : e.target.value})}></input>
                                    </div>
                                </div>
        
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="status">{i18n.t('pizza.sell.paymentStatus')}:</label>
                                        <select id="status" className="form-control text-uppercase" onChange={(e) => setMySalesSearchData({...mySalesSearchData, 'status' : e.target.value})}>
                                            <option value="">{i18n.t('selectAnOption')}</option>
                                            <option value="PENDENTE">{i18n.t('pizza.sell.open')}</option>
                                            <option value="PAGO">{i18n.t('pizza.sell.paid')}</option>
                                        </select> 
                                    </div>
                                </div>
                                
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="dataPedido">{i18n.t('pizza.sell.orderDate')}:</label>
                                        <DatePicker selected={mySalesSearchData.dataPedido} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => setMySalesSearchData({...mySalesSearchData, 'dataPedido' : date.getTime()})} />
                                    </div>
                                </div>
                            </div>
        
                            <div className="pull-right">
                                <button type="submit" className="btn btn-success" onClick={(e) => changeMenu(null, 'order')}>{i18n.t('pizza.sell.makeOrder')}</button>
                                <button type="submit" className="btn btn-primary" onClick={(e) => handleSearch(e)}>{i18n.t('search')}</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelMyOrders" data-toggle="collapse" href="#collapsePainelMyOrders">{i18n.t('pizza.sell.myOrders')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelMyOrders" aria-labelledby="painelMyOrders">
                        <Table 
                            loading={loading} 
                            search={fetchMyOrders} 
                            dadosBusca={myOrdersSearchData} 
                            tableConfig={tableConfig} 
                            objects={myOrders}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
                
                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelOrders" data-toggle="collapse" href="#collapsePainelOrders">{i18n.t('pizza.sell.mySales')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelOrders" aria-labelledby="painelOrders">
                        <Table 
                            loading={loading} 
                            search={fetchMySales} 
                            dadosBusca={mySalesSearchData} 
                            tableConfig={tableConfig} 
                            objects={mySales}
                            actions={actions}
                            handleTableActions={handleTableActions}>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={showModalVisualize} className="Modal" overlayClassName="Overlay" ariaHideApp={false} closeTimeoutMS={200}>
                { order && (
                    <div>
                        <div className="modal-header">
                            <h1 className="modal-title centralize"><b>{i18n.t('pizza.sell.details') + order.pedido.id}</b></h1>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="nomeCliente">{i18n.t('pizza.sell.customerName')}:</label> 
                                        <input id="nomeCliente" type="text" className="form-control text-uppercase" value={order.pedido.nome} disabled="disabled"></input>
                                    </div>
                                </div>
                                
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="telefone">{i18n.t('pizza.sell.phone')}:</label>
                                        <input id="telefone" type="text" className="form-control" value={order.pedido.telefone} disabled="disabled"></input>
                                    </div>
                                </div>
                                
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="qtde">{i18n.t('pizza.sell.quantityKits')}:</label>
                                        <input id="qtde" type="text" className="form-control" value={order.qtdeKits} disabled="disabled"></input>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row" style={{ display: StringUtil.isEmpty(order.pedido.observacao) ? 'none' : 'block'}}>
                                <div className="col-sm-12">
                                    <label htmlFor="observacao">{i18n.t('pizza.sell.observation')}:</label>
                                    <textarea rows="4" className="form-control text-uppercase" cols="200" maxLength="400" id="pedido.observacao" value={order.pedido.observacao} disabled="disabled"></textarea>
                                </div>
                            </div>
                            
                            <hr></hr>
                            
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="dataPedido">{i18n.t('pizza.sell.orderDate')}:</label>
                                        <input id="dataPedido" type="text" className="form-control" value={DateUtil.formatDate(order.pedido.dataPedido)} disabled="disabled"></input>
                                    </div>
                                </div>
                                
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="valorTotal">{i18n.t('pizza.sell.totalAmount')}:</label>
                                        <input id="valorTotal" type="text" className="form-control" value={MoneyUtil.format(order.pedido.valorTotal)} disabled="disabled"></input>
                                    </div>
                                </div>
                                
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="situacao">{i18n.t('pizza.sell.status')}:</label>
                                        <input id="situacao" type="text" className="form-control" value={order.pedido.status} disabled="disabled"></input>
                                    </div>
                                </div>
                            </div>
                            
                            { order.pedido.status !== 'PAGO' && (
                                <>
                                    <hr></hr>
    
                                    <div className="row">
                                        <div className="col-sm-6" style={{ display : showPaymentOrder ? 'none' : 'flex' }}>
                                            <button onClick={() => setShowPaymentOrder(true)} className="btn btn-primary submit-button float-left">Dar baixa</button>
                                        </div>
                                        <div className="col-sm-6 hide" style={{ display : showPaymentOrder ? 'flex' : 'none' }}>
                                            <button onClick={() => setShowPaymentOrder(false)} className="btn btn-danger">{i18n.t('cancel')}</button>
                                            <select className="form-control" onChange={(e) => setPaymentType(e.target.value)}>
                                                <option value="PIX">{i18n.t('pizza.sell.pix')}</option>
                                                <option value="DINHEIRO">{i18n.t('pizza.sell.money')}</option>
                                                <option value="TRANSFERENCIA">{i18n.t('pizza.sell.transfer')}</option>
                                            </select>
                                            <button onClick={() => realizarBaixaPedido(order.pedido.id)} className="btn btn-success">{i18n.t('confirm')}</button>
                                        </div>
                                    </div>
                                </>
                            )}
                            
                            <hr></hr>
                                
                            <div className="row">
                                <div className="col-sm-12">
                                    <span>{i18n.t('pizza.sell.orderKits')}:</span>
                                    <div className="table-responsive">
                                        <table id="tabela-produtos" className="table table-striped table-bordered table-hover table-condensed js-sticky-table">
                                            <thead className="aw-table-header-solid">
                                                <tr>
                                                    <th>{i18n.t('pizza.sell.quantity')}</th>
                                                    <th>{i18n.t('pizza.sell.flavour')}</th>
                                                    <th>{i18n.t('pizza.sell.unitAmount')}</th>
                                                    <th>{i18n.t('pizza.sell.totalAmount')}</th>
                                                    <th>{i18n.t('pizza.sell.status')}</th>
                                                    <th>{i18n.t('actions')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { order.kits.map((kit) => {
                                                    return (
                                                        <tr key={kit.id}>
                                                            <td>{kit.quantidade}</td>
                                                            <td>{kit.sabor}</td>
                                                            <td>{MoneyUtil.format(kit.valor)}</td>
                                                            <td>{MoneyUtil.format(kit.valor * kit.quantidade)}</td>
                                                            <td>{kit.status}</td>
                                                            <td>
                                                                <a onClick={() => realizarBaixaKitDoPedido(order.pedido.id, kit.id)} title="Dar baixa" className="pointer">
                                                                    <i className="fa fa-money-bill"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-dismiss="modal" onClick={() => setShowModalVisualize(false)}>{i18n.t('close')}</a>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}
 
export default SellPizza;