import ReactPaginate from 'react-paginate';
import TableBodyLines from './TableBodyLines';
import i18n from '../../util/translation/i18n';

const Table = ({ loading, search, dadosBusca, tableConfig, objects, actions, handleTableActions }) => {

    const handlePagination = (event) => {
        dadosBusca.numeroPagina = event.selected;
        search();
    };

    const setOrder = (column) => {
        dadosBusca.colunaDirecao = (dadosBusca.colunaDirecao + 1) % 3;

        if(dadosBusca.colunaDirecao == 0) {
            delete dadosBusca.colunaOrdenacao;
        } else {
            dadosBusca.colunaOrdenacao = column;
        }
        
        search();
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover table-condensed js-sticky-table table-ordered">
                <thead className="aw-table-header-solid">
                    <tr>
                        {
                            tableConfig.map((column, index) => {
                                return (
                                    <th 
                                        key={index} 
                                        onClick={() => setOrder(column.property)}>
                                        {column.headName}
                                        {column.sorteable && dadosBusca.colunaOrdenacao == column.property && dadosBusca.colunaDirecao > 0 && (
                                            <i className={`sorting fas ${dadosBusca.colunaDirecao === 1 ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-up-alt'}`}></i>
                                        )}
                                    </th>
                                )
                            })
                        }
                        {
                            actions && actions.length > 0 && (
                                <th className="no-order">{i18n.t('actions')}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    <TableBodyLines loading={loading} tableConfig={tableConfig} objects={objects} actions={actions} handleTableActions={handleTableActions} />
                </tbody>
            </table>

            <div className="pagination-container">
                <ReactPaginate
                    activeClassName="selected"
                    previousClassName="previous"
                    nextClassName="next"
                    pageClassName=""
                    breakLabel="..."
                    nextLabel={i18n.t('next')}
                    onPageChange={handlePagination}
                    pageRangeDisplayed={5}
                    pageCount={objects ? objects.totalPages : 0}
                    previousLabel={i18n.t('previous')}
                    renderOnZeroPageCount={false}
                />
            </div>
        </div>
    )
}

export default Table;