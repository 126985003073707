import { useState } from "react";
import PizzaEventReport from "../features/pizza/management/PizzaEventReport";
import ManagePizzaEvent from "../features/pizza/management/ManagePizzaEvent";
import SellPizza from "../features/pizza/order/SellPizza";
import SellPizzaOrder from "../features/pizza/order/SellPizzaOrder";

const MenuManagement = ({ setMessageConfig, actualMenu }) => {
    const [menu, setMenu] = useState(actualMenu);
    const [object, setObject] = useState(null);

    function changeMenu(object, newMenu) {
        setObject(object);
        setMenu(newMenu);
    }

    if(menu === 'report') {
        return <PizzaEventReport setMessageConfig={setMessageConfig} changeMenu={changeMenu} pizzaEvent={object} />
    } else if(menu === 'event') {
        return <ManagePizzaEvent setMessageConfig={setMessageConfig} changeMenu={changeMenu} />
    } else if(menu === "sell") {
        return <SellPizza setMessageConfig={setMessageConfig} changeMenu={changeMenu}></SellPizza>
    } else if(menu === "order") {
        return <SellPizzaOrder setMessageConfig={setMessageConfig} changeMenu={changeMenu}></SellPizzaOrder>
    } else {
        return <></>
    }
}
 
export default MenuManagement;