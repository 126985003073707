// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      systemName : 'CENTRO ESPÍRITA PAULO APÓSTOLO',
      systemShortName : 'C.E. PAULO APÓSTOLO',
      emailLabel: 'E-mail',
      passwordLabel: 'Password',
      loginButtonLabel: 'Login',
      loginFailure: 'It\'s not possible to login',
      emailRequired: 'Please enter your email',
      passwordRequired: 'Please enter your password',
      invalidEmail: 'Invalid email address',
      forgotPasswordTitle: 'Forgot password',
      forgotPasswordButton: 'Recover password',
      forgotPasswordRequired: 'Please enter your email for password recovery',
      send: 'Send',
      search: 'Search',
      loginTitle: 'Login',
      recoverPassword: 'Recover Password',
      goBack: 'Go Back',
      next: 'Next',
      previous: 'Previous',
      save: 'Save',
      confirm: 'Confirm',
      cancel: 'Cancel',
      close: 'Close',
      yes: 'Yes',
      no: 'No',
      edit: 'Edit',
      actions: 'Actions',
      create: 'Create',
      selectAnOption: 'Select an option',
      noItemFound: 'No item found',
      loadUserProfileFailed: 'Could not load user profile',
      loadUserPermissionsFailed: 'Could not load user permissions',
      deleteAccountSure: 'Are you sure you want to delete your account?',
      changePassword: {
        title: 'Change Password',
        email: 'Access E-mail',
        username: 'Username',
        password: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        redirectMessage: 'After change your password, you will be redirected to login screen',
        passwordChangedSuccesfully: 'Password updated successfully'
      },
      users: {
        title: 'Manage Users',
        search: 'Search users',
        result: 'Created users',
        loadFetchFailed: 'Could not load users',
        name: 'Name',
        email: 'E-mail',
        status: 'Status',
        phone: 'Phone',
        password: 'Password',
        passwordConfirmation: 'Password confirm',
        situation: 'Situation',
        statusSelection: 'Select an option',
        statusEnable: 'ENABLE',
        statusDisable: 'DISABLE',
        enable: 'enable',
        disable: 'disable',
        enableSure: 'Are you sure you wanna enable this user?',
        enabledSuccessfully: 'User enabled successfully',
        disableSure: 'Are you sure you wanna disable this user?',
        disabledSuccessfully: 'User disabled successfully',
        creation: {
          userProfile: 'User profile',
          emailInvalid: 'Invalid e-mail',
          emailMandatory: 'E-mail is mandatory',
          nameMandatory: 'Name is mandatory',
          phoneMandatory: 'Phone number is mandatory',
          passwordMandatory: 'Password is mandatory',
          passwordConfirmationMandatory: 'Password confirmation is mandatory',
          passwordConfirmationNotEquals: 'Password and confirmation must be equals',
          invitationEmailMessage: 'Send e-mail with content below',
        }
      },
      logs: {
        title: 'List Logs',
        search: 'Search logs',
        result: 'Logs',
        failedToLoad: 'Failed to search events',
        event: 'Event',
        category: 'Category',
        ip: 'IP',
        occurenceDate: 'Ocurrence date',
        user: 'User',
        detail: 'Details',
        eventSelection: 'Select an event'
      },
      config: {
        title: 'List Configurations',
        search: 'Search configs',
        result: 'Configurations',
        failedToLoad: 'Failed to search configurations',
        failedToDelete: 'Failed to delete configuration',
        failedToUpdate: 'Failed to update configuration',
        name: 'Name',
        value: 'Value',
        editConfig: 'Edit configuration',
        confirmConfigDeletion: 'Are you sure you want to delete this configuration? This action can break the whole system',
        deleted: 'Configuration deleted successfully',
        updated: 'Configuration updated successfully'
      },
      palestras: {
        title: 'Lecture',
        search: 'Search lectures',
        result: 'Lecture',
        loadFetchFailed: 'Failed to search lectures',
        id: 'ID',
        theme: 'Theme',
        speaker: 'Speaker', 
        date: 'Date',
        url: 'URL',
        embedUrl: 'Video Embedded URL',
        create: 'Create new lecture',
        confirmDeletion: 'Are you sure you want to delete this lecture?',
        failedToDelete: 'Failed to delete lecture',
        failedToCreate: 'Failed to create lecture',
        deletedSuccessfully: 'Lecture deleted successfully',
        createdSuccessfully: 'Lecture created successfully'
      },
      emails: {
        title: 'Manage E-mails',
        search: 'Search sent emails',
        result: 'Sent E-mails',
        sender: 'Sender',
        receiver: 'Receiver',
        subject: 'Subject',
        type: 'Type',
        read: 'Read',
        status : 'Status',
        createdAt : 'Created at',
        emailContent: 'E-mail content',
        deleteConfirmation: 'Are you sure you wanna delete this email?',
        deletedSuccessfully: 'The e-mail was deleted successfully',
        resendSuccessfully: 'The e-mail was resend successfully',
        failedToDelete: 'Could not delete the e-mail. A error ocurred on service.',
        failedToResend: 'Could not resend the e-mail. A error ocurred on service.',
        failedToLoad: 'Failed to search emails',
      },
      permissions: {
        title: 'Manage Permissions',
        create: 'Create new permission',
        result: 'Created permissions',
        isInternal: 'Internal permission',
        id: 'ID',
        name: 'Name',
        description: 'Description',
        url: 'URL',
        icon: 'Icon',
        showOnMenu: 'Show on menu',
        createNew: 'Create new',
        createdSuccessfully: 'Permission created successfully',
        deletedSuccessfully: 'Permissions deleted successfully',
        confirmDeletion: 'Are you sure you wanna delete this permission?',
        failedToLoad: 'Could not load the permissions',
      },
      versionControl: {
        title: 'Version Control',
        create: 'Create new version',
        search: 'Search for version',
        version: 'Version',
        deployDate: 'Deploy date',
        responsable: 'Responsable',
        result: 'Created versions',
        id: 'ID',
        description: 'Description',
        createdSuccessfully: 'New version created successfully',
        deletedSuccessfully: 'Version deleted successfully',
        confirmDeletion: 'Are you sure you want to delete this version?',
        failedToLoad: 'Could not fetch versions',
        failedToSave: 'Could not create a new version',
        failedToDelete: 'Could not delete the version'
      },
      groups: {
        title: 'Manage Groups',
        create: 'Create group',
        result: 'Created groups',
        id: 'ID',
        name: 'Name',
        creationDate: 'Creation date',
        groupName: 'Group Name',
        permissionName: 'Permission name',
        creationDate: 'Created Date',
        createNew: 'Create',
        createdSuccessfully: 'Group created successfully',
        deletedSuccessfully: 'Group deleted successfully',
        updatedSuccessfully: 'Group updated successfully',
        confirmDeletion: 'Are you sure you wanna delete this group? All users related to this group will lose their access',
        failedToLoad: 'Could not load the groups',
        relatePermission: 'Relate permission'
      },
      pizza: {
        sell: {
          title: 'Your pizza orders',
          search: 'Search order',
          makeOrder: 'Order Pizza',
          myOrders: 'My Orders',
          mySales: 'My Sales',
          name : 'Name',
          phone: 'Phone',
          paymentStatus : 'Payment Status',
          orderDate: 'Order date',
          orderNumber: 'Order number',
          customer: 'Customer',
          status : 'Status',
          amount: 'Value',
          open: 'WAITING PAYMENT',
          paid: 'PAID',
          loadFetchMyOrdersFailed: 'Could not load my orders',
          loadFetchMySalesFailed: 'Could not load my sales',
          confirmDeletion: 'Are you sure you wanna delete this order?',
          confirmOrderPayment: 'Confirm order payment?',
          confirmKitPayment: 'Confirm kit payment?',
          failedToDelete: 'Could not delete order',
          failedToPay: 'Could not pay',
          details: 'Order details - ID ',
          customerName: 'Customer name',
          quantityKits: 'Kit\'s quantity',
          observation: 'Observation',
          totalAmount: 'Total value',
          orderKits: 'Order\'s Kits',
          quantity: 'Quantity',
          flavour: 'Flavour',
          unitAmount: 'Unit value',
          pix: 'PIX',
          money: 'MONEY',
          transfer: 'TRANSFER'
        },
        buy: {
          title: 'Order Pizza',
          search: 'Customer data',
          customerName: 'Customer Name',
          customerPhone: 'Customer Phone',
          observation: 'Observation',
          chooseKits: 'Choose your kits',
          failedToFetchFlavours: 'Could not fetch pizza flavours',
          failedToOrder: 'Could not register order',
          failedToFetchSuggestions: 'Could not fetch suggestions',
          orderMadeSuccessfully: 'Order created successfully'
        },
        event: {
          title: 'Pizza Events',
          name: 'Name',
          eventDate: 'Event date',
          initialOrderDate: 'Orders initial date',
          finalOrderDate: 'Orders final date',
          loadFetchFailed: 'Could not fetch pizza events',
          eventName: 'Event name',
          status: 'Status',
          quantitySoldPizzas: 'Sold pizzas',
          spendAmount: 'Spend money',
          collectedAmount: 'Valor arrecadado',
          failedToDelete: 'Could not delete event',
          confirmDeletion: 'Are you sure you wanna delete this event?',
          failedToCreate: 'Could not create event',
          createdSuccessfully: 'Event created successfully',
          cantDeleteFinished: 'Can\'t delete a finished event'
        },
        report: {
          title: 'Eventos de Pizzas',
          edit: 'Edit Pizza Event',
          spentMoneyWithMaterial: 'Valor gasto com material',
          createdBy: 'Created by',
          createdAt: 'Created at',
          openForOrders: 'ACCEPTING ORDERS',
          doing: 'IN CONFECCTION',
          finished: 'FINISHED',
          orderByFlavour: 'Pedido por sabor',
          soldKits: 'Quantidade Total de Kits Vendidos',
          paidKits: 'Quantidade Total de Kits Pagos',
          pendingKits: 'Quantidade Total de Kits Pendentes',
          financial: 'Financeiro',
          totalSoldAmount: 'Valor total em vendas',
          paidAmount: 'Valor já pago',
          amountToReceive: 'Valor à receber',
          relationPizzaKit: 'Relation Pizza/Kit',
          quatityMiniPizza: 'Quantity of mini-pizzas',
          totalMiniPizza: 'Total mini-pizzas',
          export: 'Export reports',
          loadFetchFailed: 'Could not generate event report',
          updatedSuccessfully: 'Event updated successfully'
        }
      },
      messages: {
        title: 'Manage Messages',
        search: 'Search messages',
        createdBy: 'Created by',
        createdAt: 'Created at',
        result: 'Created messages',
        id: 'ID',
        validoAte: 'Valid until',
        dataCriacao: 'Created at',
        criadoPor: 'Created by',
        mensagem: 'Message',
        failedToLoadMessages: 'Could not load messages',
        failedToLoadCreators: 'Could not load the last message creators',
        failedToDelete: 'Could not delete the message',
        failedToCreate: 'Could not create the message',
        confirmDeletion: 'Are you sure you want to delete this message?',
        deletedSuccessfully: 'Message deleted successfully',
        createdSuccessfully: 'Message created successfully',
        create: 'Message data',
        user: 'User',
        message: 'Message'
      },
      textarea: {
        missingCharacters: 'characters missing'
      },
      BIBLIOTECA_CADASTRO: 'Register book',
      SMS: 'SMS',
      REDE_SOCIAL: 'Social Network',
      LOG: 'Logs',
      GERENCIAR_MENSAGEM: 'Manage message',
      CONTROLE_VERSAO: 'Version Control',
      CONFIGURACOES: 'Configurations',
      GERENCIAR_EVENTOS_PIZZA: 'Manage Pizza Event',
      PALESTRA: 'Lecture',
      GERENCIAR_CONTVERSAO: 'Manage Version Control',
      GERENCIAR_USUARIOS: 'Manage Users',
      GERENCIAR_GRUPOS: 'Manage Groups',
      GERENCIAR_EMAILS: 'Manage E-mails',
      BIBLIOTECA: 'Library',
      FALE_CONOSCO: 'Talk with Us',
      PEDIDO_PIZZA: 'Order Pizza',
      GERENCIAR_PERMISSOES: 'Manage Permissions'
    },
  },
  pt: {
    translation: {
      systemName : 'CENTRO ESPÍRITA PAULO APÓSTOLO',
      systemShortName : 'C.E. PAULO APÓSTOLO',
      emailLabel: 'E-mail',
      passwordLabel: 'Senha',
      loginButtonLabel: 'Entrar',
      loginFailure: 'Não foi possível efetuar o login',
      emailRequired: 'O e-mail é obrigatório',
      passwordRequired: 'A senha é obrigatória',
      invalidEmail: 'E-mail inválido',
      forgotPasswordTitle: 'Esqueci minha senha',
      forgotPasswordButton: 'Esqueci minha senha',
      forgotPasswordRequired: 'Por favor informe seu e-mail para recuperar sua senha',
      send: 'Enviar',
      search: 'Pesquisar',
      loginTitle: 'Login',
      recoverPassword: 'Recuperar minha senha',
      goBack: 'Voltar',
      next: 'Próximo',
      previous: 'Anterior',
      save: 'Salvar',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      close: 'Fechar',
      yes: 'Sim',
      no: 'Não',
      edit: 'Editar',
      actions: 'Ações',
      create: 'Cadastrar',
      selectAnOption: 'Selecione uma opção',
      noItemFound: 'Registro(s) não encontrado(s)',
      loadUserProfileFailed: 'Houve um erro ao carregar o perfil do usuário',
      loadUserPermissionsFailed: 'Houve um erro ao carregar as permissões do usuário',
      deleteAccountSure: 'Tem certeza que deseja deletar sua conta?',
      changePassword: {
        title: 'Alteração de senha',
        email: 'E-mail de acesso',
        username: 'Nome de usuário',
        password: 'Senha atual',
        newPassword: 'Nova senha',
        confirmPassword: 'Confirmação de senha',
        redirectMessage: 'Após alteração da senha, você será redirecionado para a tela de login',
        passwordChangedSuccesfully: 'Senha alterada com sucesso'
      },
      userProfile: {
        title: 'Perfil de Usuário',
        username : 'Nome do usuário',
        email: 'E-mail',
        phone: 'Telefone',
        deleteAccount: 'Deletar minha conta'
      },
      users: {
        title: 'Gerenciar Usuários',
        search: 'Pesquisar usuários',
        result: 'Usuários cadastrados',
        loadFetchFailed: 'Houve um erro ao carregar os usuários',
        name: 'Nome',
        email: 'E-mail',
        status: 'Status',
        phone: 'Telefone',
        password: 'Senha',
        passwordConfirmation: 'Confirmacao de senha',
        situation: 'Situação',
        statusSelection: 'Selecione uma opção',
        statusEnable: 'ATIVO',
        statusDisable: 'INATIVO',
        enable: 'Ativar',
        disable: 'Desativar',
        enableSure: 'Deseja realmente habilitar esse usuário?',
        enabledSuccessfully: 'Usuário habilitado com sucesso',
        disableSure: 'Deseja realmente desabilitar esse usuário?',
        disabledSuccessfully: 'Usuário desabilitado com sucesso',
        creation: {
          userProfile: 'Perfil de usuário',
          emailInvalid: 'O e-mail não é válido',
          emailMandatory: 'O e-mail é obrigatório',
          nameMandatory: 'O nome é obrigatório',
          phoneMandatory: 'O telefone é obrigatório',
          passwordMandatory: 'A senha é obrigatória',
          passwordConfirmationMandatory: 'A confirmação de senha é obrigatória',
          passwordConfirmationNotEquals: 'A senha e confirmação devem ser iguais',
          invitationEmailMessage: 'Enviar e-mail com a seguinte mensagem',
        }
      },
      logs: {
        title: 'Listar Logs',
        search: 'Pesquisar logs',
        result: 'Logs',
        failedToLoad: 'Houve um erro para buscar os eventos',
        event: 'Evento',
        category: 'Categoria',
        ip: 'IP',
        occurenceDate: 'Data de ocorrência',
        user: 'Usuário',
        detail: 'Detalhes',
        eventSelection: 'Selecione um evento'
      },
      config: {
        title: 'Listar Configurações',
        search: 'Buscar configurações',
        result: 'Configurações',
        failedToLoad: 'Houve um erro para buscar as configurações',
        failedToDelete: 'Houve um erro para deletar a configuração',
        failedToUpdate: 'Houve um erro para atualizar a configuração',
        name: 'Nome',
        value: 'Valor',
        editConfig: 'Editar configuração',
        confirmConfigDeletion: 'Tem certeza que deseja deletar essa configuração? Isso pode parar todo o sistema!',
        deleted: 'Configuração deletada com sucesso',
        updated: 'Configuração atualizada com sucesso'
      },
      palestras: {
        title: 'Palestras',
        search: 'Consulta de Palestras',
        result: 'Palestras',
        loadFetchFailed: 'Houve um erro para buscar as palestras',
        id: 'ID',
        theme: 'Tema',
        speaker: 'Palestrante', 
        date: 'Data',
        url: 'URL do vídeo',
        embedUrl: 'Embedded URL do vídeo',
        create: 'Cadastrar nova palestra',
        confirmDeletion: 'Tem certeza que deseja deletar essa palestra?',
        failedToDelete: 'Houve um erro ao deletar a palestra',
        failedToCreate: 'Failed to create lecture',
        createdSuccessfully: 'Palestra criada com sucesso',
        deletedSuccessfully: 'Palestra deletada com sucesso'
      },
      emails: {
        title: 'Gerenciar E-mails',
        search: 'Pesquisar e-mails enviados',
        result: 'E-mails Enviados',
        sender: 'Remetente',
        receiver: 'Destinatário',
        subject: 'Assunto',
        type: 'Tipo',
        read: 'Lido',
        status : 'Status',
        createdAt : 'Criado em',
        emailContent: 'Conteúdo do e-mail',
        deleteConfirmation: 'Você tem certeza que deseja deletar esse e-mail?',
        deletedSuccessfully: 'O e-mail foi deletado com sucesso',
        resendSuccessfully: 'O e-mail foi reenviado com sucesso',
        failedToDelete: 'Houve um erro ao deletar o e-mail',
        failedToResend: 'Houve um erro ao reenviar o email',
        failedToLoad: 'Houve um erro ao buscar os e-mails enviados',
      },
      permissions: {
        title: 'Gerenciar Permissões',
        create: 'Cadastrar nova permissão',
        result: 'Permissões cadastradas',
        isInternal: 'Permissão interna',
        id: 'ID',
        name: 'Nome',
        description: 'Descrição',
        url: 'URL',
        icon: 'Ícone',
        showOnMenu: 'Mostrar no menu',
        createNew: 'Cadastrar Novo',
        createdSuccessfully: 'Permissão criada com sucesso',
        deletedSuccessfully: 'Permissão deletada com sucesso',
        confirmDeletion: 'Tem certeza que deseja deletar essa permissão?',
        failedToLoad: 'Houve um erro ao buscar as permissões de sistema',
      },
      versionControl: {
        title: 'Controle de Versão',
        create: 'Inserir nova versão',
        search: 'Pesquisar versões do sistema',
        version: 'Versão',
        deployDate: 'Data de Implantação',
        responsable: 'Responsável',
        result: 'Versões Implantadas',
        id: 'ID',
        description: 'Descrição',
        createdSuccessfully: 'Nova versão cadastrada com sucesso',
        deletedSuccessfully: 'Versão deletada com sucesso',
        confirmDeletion: 'Você tem certeza que deseja deletar essa versão?',
        failedToLoad: 'Houve um erro ao buscar as versões cadastradas',
        failedToSave: 'Não foi possível cadastrar a nova versão',
        failedToDelete: 'Não foi possível deletar a versão'
      },
      groups: {
        title: 'Gerenciar grupos',
        create: 'Cadastrar grupo',
        result: 'Grupos cadastrados',
        id: 'ID',
        name: 'Nome',
        creationDate: 'Data de Cadastro',
        groupName: 'Nome do grupo',
        permissionName: 'Nome da permissão',
        createNew: 'Cadastrar',
        createdSuccessfully: 'O novo grupo foi criado com sucesso',
        deletedSuccessfully: 'O grupo foi deletado com sucesso',
        updatedSuccessfully: 'O grupo foi atualizado com sucesso',
        confirmDeletion: 'Você tem certeza que deseja deletar esse grupo? Todos os usuários vinculados à esse grupo perderão seus acessos',
        failedToLoad: 'Houve um erro ao carregar os grupos do sistema',
        relatePermission: 'Vincular Permissões'
      },
      pizza: {
        sell: {
          title: 'Seus Pedidos de Pizza',
          search: 'Pesquisar pedido',
          makeOrder: 'Pedir Pizza',
          myOrders: 'Meus Pedidos',
          mySales: 'Minhas Vendas',
          name : 'Nome',
          phone: 'Telefone',
          paymentStatus : 'Situação de Pagamento',
          orderDate: 'Data do Pedido',
          orderNumber: 'Número do Pedido',
          customer: 'Cliente',
          status : 'Situação',
          amount: 'Valor',
          open: 'PENDENTE',
          paid: 'PAGO',
          loadFetchMyOrdersFailed: 'Houve um erro ao carregar os meus pedidos',
          loadFetchMySalesFailed: 'Houve um erro ao carregar as minhas vendas',
          confirmDeletion: 'Voce tem certeza que deseja deletar esse pedido?',
          confirmOrderPayment: 'Confirmar pagamento do pedido?',
          confirmKitPayment: 'Confirmar pagamento do kit do pedido?',
          failedToDelete: 'Houve um erro ao deletar o pedido',
          failedToPay: 'Houve um erro ao registrar o pagamento',
          details: 'Detalhe do Pedido - ID ',
          customerName: 'Nome do Cliente',
          quantityKits: 'Quantidade de Kits',
          observation: 'Observação',
          totalAmount: 'Valor Total',
          orderKits: 'Kits do Pedido',
          quantity: 'Quantidade',
          flavour: 'Sabor',
          unitAmount: 'Valor Unitário',
          pix: 'PIX',
          money: 'DINHEIRO',
          transfer: 'TRANSFERÊNCIA'
        },
        buy: {
          title: 'Encomendar Pizza',
          search: 'Dados do Cliente',
          customerName: 'Nome do cliente',
          customerPhone: 'Telefone do cliente',
          observation: 'Observação',
          chooseKits: 'Escolha seus kits',
          failedToFetchFlavours: 'Houve um erro ao buscar os sabores das pizza',
          failedToOrder: 'Houve um erro ao realizar o pedido',
          failedToFetchSuggestions: 'Houve um erro ao carregar as sugestões',
          orderMadeSuccessfully: 'Seu pedido foi registrado com sucesso'
        },
        event: {
          title: 'Eventos de Pizza',
          name: 'Nome',
          eventDate: 'Data do evento',
          initialOrderDate: 'Data inicial dos pedidos',
          finalOrderDate: 'Data final dos pedidos',
          loadFetchFailed: 'Houve um erro ao listar os eventos de pizza',
          eventName: 'Nome do Evento',
          status: 'Situação',
          quantitySoldPizzas: 'Pizzas vendidas',
          spendAmount: 'Valor Gasto',
          collectedAmount: 'Valor arrecadado',
          failedToDelete: 'Houve um erro ao deletar o evento',
          confirmDeletion: 'Voce tem certeza que deseja deletar esse evento?',
          failedToCreate: 'Houve um erro ao criar o evento',
          createdSuccessfully: 'Evento criado com sucesso',
          cantDeleteFinished: 'Não é possível deletar um evento já finalizado'
        },
        report: {
          title: 'Eventos de Pizzas',
          edit: 'Editar Evento de Pizza',
          spentMoneyWithMaterial: 'Valor gasto com material',
          createdBy: 'Criado por',
          createdAt: 'Criado em',
          openForOrders: 'ABERTO PARA PEDIDOS',
          doing: 'EM CONFECÇÃO',
          finished: 'FINALIZADO',
          orderByFlavour: 'Pedido por sabor',
          soldKits: 'Quantidade Total de Kits Vendidos',
          paidKits: 'Quantidade Total de Kits Pagos',
          pendingKits: 'Quantidade Total de Kits Pendentes',
          financial: 'Financeiro',
          totalSoldAmount: 'Valor total em vendas',
          paidAmount: 'Valor já pago',
          amountToReceive: 'Valor à receber',
          relationPizzaKit: 'Relação Pizza/Kit',
          quatityMiniPizza: 'Quantidade mini-pizzas',
          totalMiniPizza: 'Total mini-pizzas',
          export: 'Exportar relatórios',
          loadFetchFailed: 'Houve um erro ao buscar o relatório do evento',
          updatedSuccessfully: 'Evento atualizado com sucesso'
        }
      },
      messages: {
        title: 'Gerenciar Mensagens',
        search: 'Pesquisar mensagens',
        createdBy: 'Criado por',
        createdAt: 'Criado em',
        result: 'Mensagens cadastradas',
        id: 'ID',
        validoAte: 'Válido até',
        dataCriacao: 'Criado em',
        criadoPor: 'Criado por',
        mensagem: 'Mensagem',
        failedToLoadMessages: 'Houve um erro ao carregar as mensagens',
        failedToLoadCreators: 'Houve um erro ao carregar os últimos criados de mensagens',
        failedToDelete: 'Houve um erro ao deletar a mensagem',
        failedToCreate: 'Houve um erro ao criar a mensagem',
        confirmDeletion: 'Você tem certeza que deseja deletar essa mensagem?',
        deletedSuccessfully: 'Mensagem deletada com sucesso',
        createdSuccessfully: 'Mensagem criada com sucesso',
        create: 'Dados da mensagem',
        user: 'Usuário',
        message: 'Incluir mensagem'
      },
      textarea: {
        missingCharacters: 'caracteres restantes'
      },
      BIBLIOTECA_CADASTRO: 'Cadastrar livro',
      SMS: 'SMS',
      REDE_SOCIAL: 'Rede Social',
      LOG: 'Log',
      GERENCIAR_MENSAGEM: 'Gerenciar Mensagem',
      CONTROLE_VERSAO: 'Controle de Versão',
      CONFIGURACOES: 'Configurações',
      GERENCIAR_EVENTOS_PIZZA: 'Eventos de Pizza',
      PALESTRA: 'Palestras',
      GERENCIAR_CONTVERSAO: 'Gerenciar Controle de Versão',
      GERENCIAR_USUARIOS: 'Usuários',
      GERENCIAR_GRUPOS: 'Grupos',
      GERENCIAR_EMAILS: 'E-mails enviados',
      BIBLIOTECA: 'Biblioteca',
      FALE_CONOSCO: 'Fale Conosco',
      PEDIDO_PIZZA: 'Pizza',
      GERENCIAR_PERMISSOES: 'Permissões'
    },
  },
  // Adicione mais idiomas conforme necessário
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt', // Idioma padrão
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
