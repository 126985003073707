class CollectionUtil {
    static isEmpty = (collection) => {
        try {
            if(collection === undefined || collection === null ) {
                return true;
            } else {
                if(collection.hasOwnProperty('content')) {
                    return collection.content === null || collection.content.length === 0;
                } else {
                    return collection.length === 0;
                }
            }
        } catch(ex) {
            console.log(ex);
            return true;
        }
    }

    static contains = (collection, value) => {
        if(this.isEmpty(collection)) {
            return false;
        } else {
            if(collection.indexOf(value) > -1) {
                return true;
            } else {
                return false;
            }
        }
    }
}

export default CollectionUtil;