import axios from 'axios';

class LoginService {
  static async login(email, password) {
    try {
      const response = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL, timeout: process.env.REACT_APP_API_TIMEOUT }).post('/auth/user', { 'email': email.trim(), 'password': password.trim() });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async forgotPassword(email) {
    try {
      const response = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL, timeout: process.env.REACT_APP_API_TIMEOUT }).post('/auth/esqueci-minha-senha', { 'email' : email });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default LoginService;