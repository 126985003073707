import { useEffect, useState } from 'react';
import i18n from '../../../util/translation/i18n';
import api from '../../../services/config/AxiosConfig';
import DatePicker from "react-datepicker";
import DateUtil from '../../../util/dateUtil';
import MoneyUtil from '../../../util/moneyUtil';
import Skeleton from 'react-loading-skeleton';

import './PizzaEventReport.css';
import excel from '../../../assets/excel.png'
import pdf from '../../../assets/pdf.png'

const PizzaEventReport = ({ setMessageConfig, changeMenu, pizzaEvent }) => {
    const [event, setEvent] = useState(pizzaEvent);
    const [report, setReport] = useState(null);
    const [creator, setCreator] = useState(null);
    const [eventToUpdate, setEventToUpdate] = useState({ ...pizzaEvent });

    useEffect(() => {
        fetchPizzaReport();
        fetchPizzaEventCreator();
    }, []);

    async function fetchPizzaReport() {
        try {
            let response = await api.get(`/admin/pizza/report/${event.id}`);
            setReport(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.report.loadFetchFailed')});
        }
    }

    async function fetchPizzaEventCreator() {
        try {
            let response = await api.get(`/usuario/${event.criadoPor}`);
            setCreator(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.report.loadFetchFailed')});
        }
    }

    async function updateEvent() {
        try {
            let response = await api.put(`/admin/pizza/${event.id}`, eventToUpdate);
            setEvent(response.data);
            setMessageConfig({'level': 'SUCCESS', 'message': i18n.t('pizza.report.updatedSuccessfully')});
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.report.loadFetchFailed')});
        }
    }

    async function downloadExcel() {
        try {
            let response = await api.get(`/admin/pizza/exportar/planilha-controle/${event.id}`);
            setCreator(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.report.loadFetchFailed')});
        }
    }

    async function downloadLabels() {
        try {
            let response = await api.get(`/adm-pizza/exportar/planilha-controle/${event.id}`);
            setCreator(response.data);
        } catch(error) {
            setMessageConfig({'message': i18n.t('pizza.report.loadFetchFailed')});
        }
    }

    return ( 
        <div className="aw-layout-page">
            <div className="page-header">
                <div className="container-fluid">
                    <h1>{i18n.t('pizza.report.title')}</h1>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="panel panel-warning">
                    <div className="panel-heading" role="button" id="painelPesquisa" data-toggle="collapse" href="#collapsePainelPesquisa">{i18n.t('pizza.report.edit')}</div>
                    <div className="panel-body panel-collapsed in collapse show" id="collapsePainelPesquisa" aria-labelledby="painelPesquisa">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="name">{i18n.t('pizza.event.name')}:</label>
                                    <input id="name" type="text" className="form-control" value={eventToUpdate.nome} maxLength="70" onChange={(e) => setEventToUpdate({...eventToUpdate, 'nome' : e.target.value })}></input>
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="dataEvento">{i18n.t('pizza.event.eventDate')}:</label>
                                    <DatePicker 
                                        id="dataEvento" 
                                        dateFormat="dd/MM/yyyy"
                                        value={DateUtil.formatDate(eventToUpdate.dataEvento)}
                                        className="form-control" 
                                        onChange={(date) => setEventToUpdate({...eventToUpdate, 'dataEvento' : date.getTime()})} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="dataInicioPedidos">{i18n.t('pizza.event.initialOrderDate')}:</label>
                                    <DatePicker 
                                        id="dataInicioPedidos" 
                                        dateFormat="dd/MM/yyyy"
                                        value={DateUtil.formatDate(eventToUpdate.dataInicioPedidos)}
                                        className="form-control" 
                                        onChange={(date) => setEventToUpdate({...eventToUpdate, 'dataInicioPedidos' : date.getTime()})} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="dataFinalPedidos">{i18n.t('pizza.event.finalOrderDate')}:</label>
                                    <DatePicker 
                                        id="dataFinalPedidos" 
                                        dateFormat="dd/MM/yyyy"
                                        value={DateUtil.formatDate(eventToUpdate.dataFinalPedidos)}
                                        className="form-control" 
                                        onChange={(date) => setEventToUpdate({...eventToUpdate, 'dataFinalPedidos' : date.getTime()})} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="valorGastoMaterial">{i18n.t('pizza.report.spentMoneyWithMaterial')}:</label>
                                    <input id="valorGastoMaterial" type="text" className="form-control" value={event.valorGastoMaterial} onChange={(e) => setEventToUpdate({...eventToUpdate, 'valorGastoMaterial' : e.target.value })}></input>
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="status">{i18n.t('pizza.event.status')}:</label>
                                    <select id="status" className="form-control" value={eventToUpdate.status} onChange={(e) => setEventToUpdate({...eventToUpdate, 'status' : e.target.value })}>
                                        <option value="ABERTO_A_PEDIDOS">{i18n.t('pizza.report.openForOrders')}</option>
                                        <option value="CONFECCAO">{i18n.t('pizza.report.doing')}</option>
                                        <option value="FINALIZADO">{i18n.t('pizza.report.finished')}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="createdBy">{i18n.t('pizza.report.createdBy')}:</label>
                                    <span id="createdBy" style={{ display: creator ? 'block' : 'none' }} className="break-line">{creator ? creator.nome : ''}</span>
                                    <Skeleton style={{ 'height': '35px', display: creator ? 'none' : 'block' }} count={1} />
                                </div>
                            </div>
                            
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor="createdAt">{i18n.t('pizza.report.createdAt')}:</label><br />
                                    <span id="createdAt" className="break-line">{DateUtil.formatDate(eventToUpdate.criadoEm)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="pull-right">
                            <button type="submit" className="btn btn-danger" onClick={() => changeMenu(null, 'event')}>{i18n.t('goBack')}</button>
                            <button type="submit" className="btn btn-success" onClick={() => updateEvent()}>{i18n.t('save')}</button>
                        </div>
                    </div>
                </div>

                { report && (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="panel panel-warning">
                                <div className="panel-heading" role="button" id="painelQtde" data-toggle="collapse" href="#collapsePainelQtde">{i18n.t('pizza.report.orderByFlavour')}</div>
                                <div className="panel-body panel-collapsed in collapse show" id="collapsePainelQtde" aria-labelledby="painelQtde">
                                    <div className="row">
                                        <div className="col-md-12 pointer">
                                            <div className="panel">
                                                <span>{i18n.t('pizza.report.soldKits')}: {report.quantidadeKitsVendidos}</span><br />
                                                <span>{i18n.t('pizza.report.paidKits')}: {report.quantidadeKitsPagos}</span><br />
                                                <span>{i18n.t('pizza.report.pendingKits')}: {report.quantidadeKitsPendentes}</span>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className="row">
                                        { report.quantidadeKitsPorSabor.map((kitPorSabor, index) => {
                                            return (
                                                <div className="col-md-4 pointer" key={index}>
                                                    <div className={`panel panel-qtde-${kitPorSabor.nomeCssClass}`}>
                                                        <div className="panel-heading centralize"><span>{kitPorSabor.sabor}</span></div>
                                                        <div className="panel-body panel-collapsed in collapse show">
                                                            <div className="row centralize">
                                                                <span>{ kitPorSabor.quantidade <= 1 ? `${kitPorSabor.quantidade} kit` : `${kitPorSabor.quantidade} kits`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="panel panel-warning">
                                <div className="panel-heading" role="button" id="painelQtdePizza" data-toggle="collapse" href="#collapsePainelQtdePizza">{i18n.t('pizza.report.financial')}</div>
                                <div className="panel-body panel-collapsed in collapse show" id="collapsePainelQtdePizza" aria-labelledby="painelQtdePizza">
                                    <div className="row">
                                        <div className="col-md-12 pointer">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered table-hover table-condensed js-sticky-table">
                                                    <thead className="aw-table-header-solid">
                                                        <tr>
                                                            <th>{i18n.t('pizza.report.totalSoldAmount')}</th>
                                                            <th>{i18n.t('pizza.report.paidAmount')}</th>
                                                            <th>{i18n.t('pizza.report.amountToReceive')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span>{ MoneyUtil.format(report.valorVendas) }</span>
                                                            </td>
                                                            <td>
                                                                <span>{ MoneyUtil.format(report.valorJaPago) }</span>
                                                            </td>
                                                            <td>
                                                                <span>{ MoneyUtil.format(report.valorAReceber) }</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="panel panel-warning">
                                <div className="panel-heading" role="button" id="painelFinanceiro" data-toggle="collapse" href="#collapsePainelFinanceiro">{i18n.t('pizza.report.relationPizzaKit')}</div>
                                <div className="panel-body panel-collapsed in collapse show" id="collapsePainelFinanceiro" aria-labelledby="painelFinanceiro">
                                    <div className="row">
                                        <div className="col-md-12 pointer">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered table-hover table-condensed js-sticky-table">
                                                    <thead className="aw-table-header-solid">
                                                        <tr>
                                                            <th>{i18n.t('pizza.sell.flavour')}</th>
                                                            <th>{i18n.t('pizza.report.quatityMiniPizza')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { report.relacaoSaborQuantidade.map((relacao, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span>{relacao.sabor}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{relacao.quantidade}</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th scope="row">{i18n.t('pizza.report.totalMiniPizza')}</th>
                                                            <td>{report.quantidadeMiniPizzas}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="panel panel-warning">
                                <div className="panel-heading" role="button" id="painelDownloadReport" data-toggle="collapse" href="#collapsePainelDownloadReport">{i18n.t('pizza.report.export')}</div>
                                <div className="panel-body panel-collapsed in collapse show" id="collapsePainelDownloadReport" aria-labelledby="painelDownloadReport">
                                    <div className="row" style={{ 'display' : 'flex', 'justifyContent' : 'center' }}>
                                        <div className="col-md-6">					
                                            <a onClick={() => downloadExcel()} title="Exportar planilha de controle">
                                                <img src={excel} width="48px" height="48px"></img>
                                            </a>
                                        </div>
                                        
                                        <div className="col-md-6">					
                                            <a onClick={() => downloadLabels()} title="Exportar etiqueta">
                                                <img src={pdf} width="48px" height="48px"></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}
 
export default PizzaEventReport;