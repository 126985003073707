import React, { useState } from 'react';
import './App.css';
import DisplayMessage from './components/display-message/DisplayMessage';
import Login from './login/Login';
import ForgotPassword from './forgot-password/ForgotPassword';
import i18n from './util/translation/i18n';

const App = () => {
  const [messageConfig, setMessageConfig] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleChangeScreen = () => {
    setForgotPassword(!forgotPassword)
  }

  const handleCloseMessage = () => {
    setMessageConfig({});
  };

  return (
    <div className="aw-layout-page">
			<nav className="navbar navbar-fixed-top js-sticky-reference login-center-div" id="main-navbar">
				<div>
          <DisplayMessage messageConfig={messageConfig} onClose={handleCloseMessage} />

          <div className="wrapper">
						<div align="center">
							<span className="panelCentralizar">{ i18n.t('systemName')}</span>
						</div>

            <Login render={!forgotPassword} goBack={handleChangeScreen} setMessageConfig={setMessageConfig}/>
            <ForgotPassword render={forgotPassword} goBack={handleChangeScreen} setMessageConfig={setMessageConfig}/>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default App;
